<template>
  <div class="page-redeem-prizes">
    <!-- banner -->
    <div class="section-banner section-box"></div>
    <!-- form -->
    <div class="section-form section-box">
      <div class="content-box">
        <el-form
          class="form-box"
          :label-position="labelPosition"
          label-width="80px"
          :model="form"
          :rules="rules"
          ref="form"
          @submit.native.prevent
        >
          <el-form-item
            label="Verify Code"
            class="input-part ui-m-r-40"
            prop="verifyCode"
          >
            <el-input v-model="form.verifyCode"></el-input>
          </el-form-item>
          <img
            @click="onChangeCodeImge"
            class="img-code"
            :src="redeemCodeImg"
            alt=""
          />
          <el-form-item
            label="Redeem Code"
            class="input-part ui-m-r-40"
            prop="redeemCode"
          >
            <el-input v-model="form.redeemCode"></el-input>
          </el-form-item>
          <button @click="onApply" class="bt-apply">APPLY</button>
          <div class="prizes-box" v-if="productImg">
            <p class="title">My Prize</p>
            <div class="img-prizes">
              <div class="img-box">
                <img v-if="productImg" :src="productImg" alt="" />
              </div>
              <p class="text-info">
                Product：{{ productName }}<br />Price: {{ productPrizes }}
              </p>
            </div>
          </div>
          <div class="upload-box">
            <p class="title">Instagram Post</p>
            <p class="text-tips to-b">Please upload your Instagram Post</p>
            <p class="text-tips to-t">
              (The maximum upload size is 10Mbs accept png/jpg/jpeg format
              only.)
            </p>
          </div>
          <el-upload
            action=""
            :auto-upload="autoupload"
            list-type="picture-card"
            :limit="1"
            :on-exceed="handleExceed"
            :http-request="afterRead"
            :on-success="handleSuccess"
            :on-remove="handleRemove"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          <LkBt @submit="onNextBt" class="sb-bt" :msg="'Next >'"></LkBt>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import LkBt from "../components/LkBt.vue";
import { getPrizeInfo } from "@/api/redeem";
import { uploadFile } from "@/api/upload";
import { goPageByName } from "@/util/link";
export default {
  name: "RedeemPrizesView",
  data() {
    return {
      form: {
        redeemCode: "",
        verifyCode: "",
      },
      rules: {
        redeemCode: [
          {
            required: true,
            message: "Please enter the exchange code",
            trigger: "blur",
          },
        ],
        verifyCode: [
          {
            required: true,
            message: "Please enter the verification code",
            trigger: "blur",
          },
        ],
      },
      labelPosition: "top",
      redeemCodeImg: "",
      productName: "name",
      productImg: "",
      productPrizes: "0",
      codeId: "",
      fileList: [],
      screenshot: "",
      redeemCodeError: false,
      autoupload: true,
      uploadHeaders: {
        "Content-Type": "multipart/form-data",
      },
    };
  },
  components: {
    LkBt,
  },
  mounted() {
    console.log(this.$route.query);
    this.redeemCode = this.$route.query.redeemCode || "";
    this.onChangeCodeImge();
  },
  methods: {
    async afterRead(file) {
      // 此时可以自行将文件上传至服务器
      const formData = new FormData();
      formData.append("file", file.file);
      const res = await uploadFile(formData);
      if (res.code === 0) {
        this.screenshot = res.data.url;
      } else {
        this.$message(res.msg);
      }
    },
    onChangeCodeImge() {
      this.redeemCodeImg = `//api.luckybox.au/captcha/v1/create?t=${new Date().getTime()}`;
    },
    onApply() {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          const res = await getPrizeInfo({
            redeemCode: this.form.redeemCode,
            verifyCode: this.form.verifyCode,
          });
          if (res.code === 0) {
            this.codeId = res.data.codeId;
            this.productImg = res.data.image;
            this.productPrizes = res.data.price;
            this.productName = res.data.name;
          } else {
            this.onChangeCodeImge();
            this.$message(res.msg);
            this.redeemCodeError = true;
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    onNextBt() {
      if (!this.codeId) {
        this.$message("Please enter the exchange code");
        return;
      }
      if (!this.screenshot) {
        this.$message("Please Upload Your Instagram Post");
        return;
      }
      goPageByName({
        name: "address",
        query: {
          codeId: this.codeId,
          screenshot: this.screenshot,
          redeemCode: this.form.redeemCode,
          type: "redeemprizes",
        },
      });
    },
    onFocus() {
      this.redeemCodeError = false;
    },
    handleExceed() {
      this.$message.warning(`Only one image can be uploaded`);
    },
    handleSuccess() {
      document.querySelector(".el-upload--picture-card").style.display = "none";
    },
    handleRemove() {
      document.querySelector(".el-upload--picture-card").style.display =
        "block";
      this.screenshot = "";
    },
  },
};
</script>
<style lang="scss" scoped>
.page-redeem-prizes {
  width: 100%;
  .section-banner {
    width: 100%;
    height: 360px;
    position: relative;
    background: url("../assets/img/redeemprizes/banner.png") no-repeat center;
    background-size: 1920px 360px;
  }
  .section-form {
    background: #f4f5f6;
    .content-box {
      width: 880px;
      margin: 0 auto;
      padding: 45px 0 120px;
    }
    .form-box::v-deep {
      text-align: left;
      &.el-form--label-top .el-form-item__label {
        font-size: 26px;
        font-family: AlibabaPuHuiTiH;
        color: #000000;
        line-height: 35px;
        padding-bottom: 20px;
      }
      .input-part {
        width: 700px;
        display: inline-block;
        box-sizing: border-box;
        margin-right: 20px;
      }
      .el-input__inner {
        height: 60px;
        border-radius: 8px;
        border: 1px solid #979797;
        background: #f4f5f6;
        font-size: 20px;
      }
      .el-form-item {
        margin-top: 40px;
        margin-bottom: 0;
      }
      .el-col-11 {
        width: 100%;
      }
      .is-error {
        .el-input__inner {
          border: 1px solid #ff0000;
        }
      }
      .el-form-item__error {
        font-size: 18px;
        font-family: AlibabaPuHuiTiR;
        color: #ff0000;
        line-height: 26px;
        // margin-top: 10px;
        padding: 0;
      }
      .el-upload--picture-card {
        width: 320px;
        height: 450px;
      }
      .el-upload--picture-card i {
        width: 60px;
        height: 60px;
        background: url("../assets/img/redeemprizes/icon-+.png") no-repeat
          center;
        background-size: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        &::before {
          display: none;
        }
      }
      .el-upload--picture-card {
        position: relative;
      }
      .el-upload-list--picture-card .el-upload-list__item {
        width: 320px;
        height: 450px;
        margin-right: 60px;
      }
      .el-upload-list--picture-card .el-upload-list__item-thumbnail {
        object-fit: cover;
      }
      .el-upload--picture-card i {
        width: 70px;
        height: 107px;
        background: url("../assets/img/redeemprizes/icon-upload.png") no-repeat
          center;
        background-size: 100%;
      }
    }
    .bt-apply {
      width: 160px;
      height: 60px;
      line-height: 60px;
      background: #000000;
      border-radius: 8px;
      border: 2px solid #000000;
      box-sizing: border-box;
      vertical-align: bottom;
      font-size: 20px;
      font-family: AlibabaPuHuiTiM;
      color: #ffffff;
    }
    .img-code {
      width: 160px;
      height: 60px;
      border-radius: 8px;
      vertical-align: bottom;
    }
    .prizes-box {
      margin-top: 80px;
      padding-bottom: 80px;
      border-bottom: 1px dashed #a7a7a8;
      .title {
        font-size: 26px;
        font-family: AlibabaPuHuiTiH;
        color: #000000;
        line-height: 35px;
      }
      .img-prizes {
        margin-top: 20px;
        position: relative;
        .img-box {
          width: 320px;
          height: 450px;
          background: #e9e9e9;
          border-radius: 8px;
          border: 1px solid #979797;
          overflow: hidden;
          text-align: center;
        }
        img {
          width: auto;
          height: 450px;
        }
        .text-info {
          font-size: 20px;
          font-family: AlibabaPuHuiTiR;
          color: #000000;
          line-height: 27px;
          position: absolute;
          left: 350px;
          bottom: 20px;
        }
      }
    }
    .upload-box {
      margin-top: 80px;
      .title {
        font-size: 26px;
        font-family: AlibabaPuHuiTiH;
        color: #000000;
        line-height: 35px;
      }
      .text-tips {
        font-size: 20px;
        font-family: AlibabaPuHuiTiR;
        color: #000000;
        line-height: 27px;
        margin: 20px 0 28px;
        &.to-b {
          margin-bottom: 0;
        }
        &.to-t {
          margin-top: 0;
        }
      }
    }
    .sb-bt::v-deep {
      width: 742px;
      margin-top: 99px;
      .ui-lk-bt {
        width: 100%;
        height: 60px;
        line-height: 55px;
        background: #f4cc25;
        font-size: 20px;
        font-family: AlibabaPuHuiTiM;
        color: #000;
        z-index: 10;
        position: relative;
        border-radius: 8px;
        border: 2px solid #000000;
        box-sizing: border-box;
      }
      .ui-lk-bt-i {
        width: 100%;
        height: 60px;
        box-sizing: border-box;
        background: #fff;
        border-radius: 8px;
        border: 2px solid #231815;
        position: absolute;
        z-index: 9;
        top: 9px;
        left: 0;
      }
      &.ui-lk-bt-box:active .ui-lk-bt,
      .ui-lk-bt:active {
        top: 4px;
      }
    }
    .text-policy {
      height: 26px;
      font-size: 16px;
      font-family: AlibabaPuHuiTiR;
      color: #000000;
      line-height: 26px;
      display: flex;
      align-items: center;
      margin-top: 20px;
      i {
        width: 18px;
        height: 18px;
        background: url("../assets/img/create/icon-n.png") no-repeat center;
        background-size: 100%;
        margin-right: 5px;
        cursor: pointer;
        &.active {
          background: url("../assets/img/create/icon-y.png") no-repeat center;
          background-size: 100%;
        }
      }
      span {
        color: #000;
        font-weight: bold;
        margin: 0 5px;
      }
    }
  }
}
</style>
