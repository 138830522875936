const domain = "//api.luckybox.au";

export default {
  // user
  // 注册
  register: `${domain}/user/v1/register`,
  // 邮箱密码登录
  getInfo: `${domain}/user/v1/getInfo`,
  // 获取登录用户信息
  loginByEmailPwd: `${domain}/user/v1/loginByEmailPwd`,
  // 修改个人信息接口
  saveUserInfo: `${domain}/user/v1/saveUserInfo`,
  // 密码重置
  resetPwd: `${domain}/user/v1/resetPwd`,
  // 发送密码重置邮件
  forgetPwd: `${domain}/user/v1/forgetPwd`,

  // submission
  // 提交心愿/故事
  submitText: `${domain}/submission/v1/submit`,
  getMaterial: `${domain}/material/v1/get`,

  // points
  // 获取积分记录
  getPointsLog: `${domain}/points/v1/getPointsLog`,

  // redeem
  // 兑换码获取奖品信息
  getPrizeInfo: `${domain}/redeem/v1/getPrizeInfo`,
  // 提交兑奖
  submitRedeem: `${domain}/redeem/v1/submit`,
  // 获取兑奖列表
  getRedeemList: `${domain}/redeem/v1/getRedeemList`,

  // address
  // 设置收货地址
  setAddress: `${domain}/address/v1/setAddress`,
  getAddress: `${domain}/address/v1/get/`,
  myAddress: `${domain}/address/v1/myAddress`,

  // upload
  // 上传文件
  uploadFile: `${domain}/upload/v1/file`,
};
