<template>
  <div class="page-remeem-success">
    <!-- success -->
    <div class="section-success section-box">
      <div class="content-box">
        <div class="img-box">
          <img src="../assets/img/remeemsuccess/icon-success.png" alt="" />
        </div>
        <h1 class="title">Success!</h1>
        <p class="text">
          The Lucky Box Team will review the information provided and upon
          confirmation<br />will ship out the prize within 7 working days.
        </p>
        <!-- <p class="text">
          You will automatically receive updates to shipping via email!
        </p> -->
        <p class="text">Thank you for your support!</p>
        <LkBt
          @submit="goPageByName('redeemhistory')"
          class="bt-my"
          :msg="'MY PRIZES'"
        ></LkBt>
        <LkBt
          @submit="goPageByName('home')"
          class="bt-home"
          :msg="'HOME'"
        ></LkBt>
      </div>
    </div>
  </div>
</template>
<script>
import LkBt from "../components/LkBt.vue";
import { goPageByName } from "@/util/link";
export default {
  name: "RemeemSuccessView",
  data() {
    return {};
  },
  components: {
    LkBt,
  },
  mounted() {
    console.log(this.$route.query);
  },
  methods: {
    goPageByName(name) {
      goPageByName({ name });
    },
  },
};
</script>
<style lang="scss" scoped>
.page-remeem-success {
  width: 100%;
  background: #f4f5f6;
  .section-success {
    .content-box {
      padding: 75px 0 135px;
    }
    .img-box {
      width: 590px;
      height: 300px;
      margin: 0 auto;
    }
    .title {
      font-size: 40px;
      font-family: AlibabaPuHuiTiH;
      color: #000000;
      line-height: 55px;
      margin-top: 20px;
    }
    .text {
      font-size: 20px;
      font-family: AlibabaPuHuiTiM;
      color: #000000;
      line-height: 34px;
      margin-top: 10px;
    }
  }
  .bt-my::v-deep {
    width: 270px;
    margin: 50px auto 0;
    display: block;
    .ui-lk-bt {
      width: 100%;
      height: 60px;
      line-height: 55px;
      background: #f4cc25;
      font-size: 20px;
      font-family: AlibabaPuHuiTiM;
      color: #000;
      z-index: 10;
      position: relative;
      border-radius: 8px;
      border: 2px solid #000000;
      box-sizing: border-box;
    }
    .ui-lk-bt-i {
      width: 100%;
      height: 60px;
      box-sizing: border-box;
      background: #fff;
      border-radius: 8px;
      border: 2px solid #231815;
      position: absolute;
      z-index: 9;
      top: 9px;
      left: 0;
    }
    &.ui-lk-bt-box:active .ui-lk-bt,
    .ui-lk-bt:active {
      top: 4px;
    }
  }
  .bt-home::v-deep {
    width: 270px;
    margin-top: 24px;
    .ui-lk-bt {
      width: 100%;
      height: 60px;
      line-height: 55px;
      background: #fff;
      font-size: 20px;
      font-family: AlibabaPuHuiTiM;
      color: #000;
      z-index: 10;
      position: relative;
      border-radius: 8px;
      border: 2px solid #000000;
      box-sizing: border-box;
    }
    .ui-lk-bt-i {
      width: 100%;
      height: 60px;
      box-sizing: border-box;
      background: #f4cc25;
      border-radius: 8px;
      border: 2px solid #231815;
      position: absolute;
      z-index: 9;
      top: 9px;
      left: 0;
    }
    &.ui-lk-bt-box:active .ui-lk-bt,
    .ui-lk-bt:active {
      top: 4px;
    }
  }
}
</style>
