<template>
  <div class="page-store">
    <!-- banner -->
    <div class="section-banner"></div>
    <div class="section-map">
      <div class="content-box">
        <div class="map" id="map"></div>
        <div class="store-box">
          <p class="title">Vending Machine</p>
          <button class="bt" @click="onMyLocation">
            <i></i>
            Locate current position
          </button>
          <ul class="store-list">
            <li
              class="store-item"
              v-for="(item, index) in restaurants"
              :key="index"
              @click="resetCenter(item)"
            >
              <p class="name">{{ index + 1 }}.{{ item.name }}</p>
              <p class="place">
                <i></i>
                {{ item.desc }}
              </p>
              <p class="time">
                <i></i>
                {{ item.time }}
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import storeJson from "@/util/store.json";
export default {
  name: "StoreView",
  components: {},
  data() {
    return {
      map: null,
      // 預設經緯度在信義區附近
      lat: -33.79700082851847,
      lng: 151.18220342777065,
      restaurants: [],
      // 存放目前開啟的訊息視窗
      infowindow: null,
      loading: "",
      myLocation: {},
    };
  },
  mounted() {
    if (!window.google) {
      this.loading = this.$loading({
        lock: true,
        text: "Loading",
        fullscreenLoading: true,
      });
    }
    this.init();
    setTimeout(() => {
      if (this.loading) this.loading.close();
    }, 10000);
  },
  methods: {
    fetchRestaurants() {
      this.restaurants = storeJson.restaurants;
      console.log("restaurants", this.restaurants);
      this.lat = storeJson.center.lat;
      this.lng = storeJson.center.lng;
    },
    // 建立地圖
    initMap() {
      // 透過 Map 物件建構子建立新地圖 map 物件實例，並將地圖呈現在 id 為 map 的元素中
      this.map = new window.google.maps.Map(document.getElementById("map"), {
        // 設定地圖的中心點經緯度位置
        center: { lat: this.lat, lng: this.lng },
        // 設定地圖縮放比例 0-20
        zoom: 15,
        // 限制使用者能縮放地圖的最大比例
        maxZoom: 20,
        // 限制使用者能縮放地圖的最小比例
        minZoom: 3,
        // 設定是否呈現右下角街景小人
        streetViewControl: false,
        // 設定是否讓使用者可以切換地圖樣式：一般、衛星圖等
        mapTypeControl: false,
      });
    },
    // 建立地標
    setMarker() {
      // 為每間餐廳都建立地標、訊息視窗、事件監聽
      const icon = {
        url: "https://dx7j8183yi1gy.cloudfront.net/static/m/img/icon-location-2.png",
        scaledSize: new window.google.maps.Size(40, 72),
      };
      this.restaurants.forEach((location) => {
        const marker = new window.google.maps.Marker({
          // 設定為該餐廳的座標
          position: { lat: location.lat, lng: location.lng },
          map: this.map,
          icon,
        });
        // 建立訊息視窗
        const infowindow = new window.google.maps.InfoWindow({
          content: `
          <div id="content">
            <p id="firstHeading" class="firstHeading">${location.name}</p>
          </div>
        `,
          maxWidth: 200,
        });
        // 綁定點擊事件監聽
        marker.addListener("click", () => {
          // 如果目前有開啟中的訊息視窗，先將其關閉
          if (this.infowindow) this.infowindow.close();
          // 顯示被點擊地標的訊息視窗
          infowindow.open(this.map, marker);
          // 存入目前開啟的訊息視窗
          this.infowindow = infowindow;
        });
      });
    },
    // 重設地圖中心點
    resetCenter(item = {}) {
      // set center
      this.map.panTo({ lat: item.lat || this.lat, lng: item.lng || this.lng });
    },
    init() {
      const self = this;
      if (window.google) {
        if (this.loading) this.loading.close();
        this.initMap();
        // 取得資料
        this.fetchRestaurants();
        // 使用資料建立地標
        this.setMarker();
      } else {
        setTimeout(() => {
          self.init();
        }, 1000);
      }
    },
    onMyLocation() {
      if (this.myLocation.lat) {
        this.resetCenter(this.myLocation);
        return;
      }
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const pos = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            this.myLocation = pos;
            console.log(pos);
            // 在地图上标记当前位置
            const marker = new window.google.maps.Marker({
              position: pos,
              map: this.map,
              title: "Your location",
            });
            // 建立訊息視窗
            const infowindow = new window.google.maps.InfoWindow({
              content: `
              <div id="content">
                <p id="firstHeading" class="firstHeading">Your location</p>
              </div>
            `,
              maxWidth: 200,
            });
            // 顯示被點擊地標的訊息視窗
            infowindow.open(this.map, marker);
            // 将地图中心设置为当前位置
            this.map.setCenter(pos);
          },
          () => {
            this.$message(
              "Failed to obtain location. Please check if the settings allow the current browser to obtain location"
            );
            console.log("Error: The Geolocation service failed.");
          }
        );
      } else {
        this.$message("Your browser doesn't support geolocation.");
        console.log("Error: Your browser doesn't support geolocation.");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.page-store {
  width: 100%;
  .section-banner {
    width: 100%;
    height: 359px;
    background: url("../assets/img/store/banner.png") no-repeat center;
    background-size: 1920px 359px;
  }
  .section-map {
    width: 100%;
    padding: 125px 0 120px;
    background: #f4f5f6;
    .content-box {
      width: 1190px;
      height: 725px;
      margin: 0 auto;
      border-radius: 20px;
      border: 3px solid #000000;
      padding: 20px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      background: #f4f5f6;
    }
    .map {
      width: 682px;
      height: 685px;
      background: #d8d8d8;
    }
    .store-box {
      width: 448px;
      height: 685px;
      background: #fff;
      border-radius: 14px;
      padding: 20px 24px;
      box-sizing: border-box;
      .bt {
        width: 398px;
        height: 49px;
        background: #fff;
        border-radius: 8px;
        border: 1px solid #000000;
        font-size: 16px;
        font-family: AlibabaPuHuiTiM;
        color: #000000;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 16px;
        cursor: pointer;
        i {
          width: 16px;
          height: 16px;
          background: url("../assets/img/store/icon-my.png") no-repeat center;
          background-size: 100%;
          display: inline-block;
          margin-right: 6px;
        }
      }
      .title {
        font-size: 22px;
        font-family: AlibabaPuHuiTiH;
        color: #000000;
        line-height: 30px;
      }
      .store-list {
        height: 540px;
        margin-top: 13px;
        overflow: auto;
        .store-item {
          margin-bottom: 26px;
          cursor: pointer;
          .name {
            font-size: 20px;
            font-family: AlibabaPuHuiTiM;
            color: #000000;
            line-height: 27px;
          }
          .place {
            width: 100%;
            margin-top: 8px;
            padding-left: 24px;
            box-sizing: border-box;
            font-size: 16px;
            font-family: AlibabaPuHuiTiR;
            color: rgba(0, 0, 0, 0.65);
            line-height: 20px;
            position: relative;
            i {
              width: 18px;
              height: 18px;
              background: url("../assets/img/store/icon-place.png") no-repeat
                center;
              background-size: 100%;
              position: absolute;
              top: 3px;
              left: 0;
            }
          }
          .time {
            padding-left: 24px;
            box-sizing: border-box;
            font-size: 16px;
            font-family: AlibabaPuHuiTiR;
            color: rgba(0, 0, 0, 0.65);
            line-height: 14px;
            line-height: 20px;
            position: relative;
            i {
              width: 18px;
              height: 18px;
              background: url("../assets/img/store/icon-time.png") no-repeat
                center;
              background-size: 100%;
              position: absolute;
              top: 50%;
              left: 0;
              transform: translate3d(0, -50%, 0);
            }
          }
        }
      }
    }
  }
}
</style>
