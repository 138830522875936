<template>
  <div class="page-basicinfo">
    <!-- banner -->
    <div class="section-banner section-box"></div>
    <!-- form -->
    <div class="section-form section-box">
      <div class="content-box">
        <el-form
          class="form-box"
          :label-position="labelPosition"
          label-width="80px"
          :model="form"
          :rules="RULES"
          ref="form"
          @submit.native.prevent
        >
          <el-form-item
            label="First Name"
            class="input-part ui-m-r-40"
            prop="firstName"
          >
            <el-input v-model="form.firstName"></el-input>
          </el-form-item>
          <el-form-item label="Last Name" class="input-part" prop="lastName">
            <el-input v-model="form.lastName"></el-input>
          </el-form-item>
          <el-form-item label="Gender">
            <el-radio-group v-model="form.gender">
              <el-radio :label="1">Male</el-radio>
              <el-radio :label="2">Female</el-radio>
              <el-radio :label="0">Rather Not to Say</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="Email address" prop="email">
            <el-input v-model="form.email"></el-input>
          </el-form-item>
          <el-form-item label="Birthday">
            <el-col :span="11">
              <el-date-picker
                :clearable="clearable"
                type="date"
                placeholder="Birthday"
                v-model="form.birthday"
                style="width: 100%"
                @change="onDateConfirm"
                class="time"
              ></el-date-picker>
            </el-col>
          </el-form-item>
          <el-form-item label="Phone Number" prop="phone" class="phone-box">
            <el-input type="tel" max-length="9" v-model="form.phone"></el-input>
            <i>+61</i>
          </el-form-item>
          <LkBt @submit="onSubmit" class="sb-bt" :msg="'CONFIRM'"></LkBt>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import LkBt from "../components/LkBt.vue";
import { saveUserInfo, getInfo } from "@/api/user";
import RULES from "@/util/rules";

export default {
  name: "BasicinfoView",
  data() {
    return {
      labelPosition: "top",
      minDate: new Date(1980, 0, 1),
      maxDate: new Date(),
      currentDate: new Date(2000, 1, 1),
      dateshow: false,
      isChooseDate: false,
      form: {
        firstName: "",
        lastName: "",
        birthday: "2000-01-01",
        gender: "3",
        phone: "",
        email: "",
      },
      // rules: {
      //   firstName: [
      //     {
      //       required: true,
      //       message: "Please enter First Name",
      //       trigger: "blur",
      //     },
      //   ],
      //   lastName: [
      //     {
      //       required: true,
      //       message: "Please enter Last Name",
      //       trigger: "blur",
      //     },
      //   ],
      //   phone: [
      //     {
      //       required: true,
      //       message: "Please enter a valid phone number (only digits allowed)",
      //       trigger: "blur",
      //     },
      //     {
      //       pattern: /^\d{9}$/,
      //       message: "Please enter a valid phone number (only digits allowed)",
      //       trigger: "blur",
      //     },
      //   ],
      //   email: [
      //     {
      //       required: true,
      //       message: "Please enter a valid email address",
      //       trigger: "blur",
      //     },
      //     {
      //       pattern: /@.*\.com/,
      //       message: "Please enter a valid email address",
      //       trigger: "blur",
      //     },
      //   ],
      // },
      phonePattern: /\d{9}/,
      emailPattern: /@.*\.com/,
      clearable: false,
      RULES,
    };
  },
  components: {
    LkBt,
  },
  mounted() {
    console.log(this.$route.query);
    this.getInfo();
  },
  methods: {
    onSubmit() {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          const res = await saveUserInfo({
            firstName: this.form.firstName,
            lastName: this.form.lastName,
            gender: this.form.gender,
            birthday: this.form.birthday,
            phone: this.form.phone,
          });
          if (res.code === 0) {
            this.$message({
              message: "success",
              type: "success",
            });
          } else {
            this.$message(res.msg);
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    onDateConfirm(value) {
      const y = value.getFullYear();
      const m = value.getMonth() + 1;
      const d = value.getDate();
      const birthday = `${y}-${this.changeDate(m)}-${this.changeDate(d)}`;
      this.form.birthday = birthday;
      console.log("birthday", birthday);
      console.log("value", value);
      this.isChooseDate = true;
      this.dateshow = false;
    },
    // 日期格式
    changeDate(value) {
      if (value <= 9) {
        return "0" + value;
      }
      return value;
    },
    // 获取个人信息
    async getInfo() {
      const res = await getInfo();
      console.log(res);
      if (res.code === 0) {
        this.form = res.data;
      } else {
        this.$message(res.msg);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.page-basicinfo {
  width: 100%;
  .section-banner {
    width: 100%;
    height: 360px;
    position: relative;
    background: url("../assets/img/basicinfo/banner.jpg") no-repeat center;
    background-size: 1920px 360px;
  }
  .section-form {
    background: #f4f5f6;
    .content-box {
      width: 740px;
      margin: 0 auto;
      padding: 45px 0 120px;
    }
    .form-box::v-deep {
      text-align: left;
      &.el-form--label-top .el-form-item__label {
        font-size: 20px;
        font-family: AlibabaPuHuiTiR;
        color: #000000;
        line-height: 27px;
      }
      .input-part {
        width: 350px;
        display: inline-block;
      }
      .el-input__inner {
        height: 60px;
        border-radius: 8px;
        border: 1px solid #979797;
        background: #f4f5f6;
        font-size: 20px;
      }
      .el-form-item {
        margin-top: 40px;
        margin-bottom: 0;
      }
      .el-col-11 {
        width: 100%;
      }
      .is-error {
        .el-input__inner {
          border: 1px solid #ff0000;
        }
      }
      .el-form-item__error {
        font-size: 18px;
        font-family: AlibabaPuHuiTiR;
        color: #ff0000;
        line-height: 26px;
        // margin-top: 10px;
        padding: 0;
      }
      .el-radio-group {
        display: flex;
      }
      .el-radio {
        height: 22px;
        display: flex;
        align-items: center;
        margin-right: 100px;
      }
      .el-radio__label {
        height: 22px;
        font-size: 16px;
        font-family: AlibabaPuHuiTiR;
        color: #000000;
        line-height: 22px;
        padding-left: 8px;
      }
      .el-radio__input {
        width: 18px;
        height: 18px;
      }
      .is-checked .el-radio__inner {
        width: 18px;
        height: 18px;
        background: url("../assets/img/create/icon-y.png") no-repeat center;
        background-size: 100%;
        border: none;
      }
      .el-radio__inner {
        width: 18px;
        height: 18px;
        background: url("../assets/img/create/icon-n.png") no-repeat center;
        background-size: 100%;
      }
      .time {
        position: relative;
        .el-input__suffix {
          width: 20px;
          height: 9px;
          background: url("../assets/img/create/icon-san.png") no-repeat center;
          background-size: 100%;
          position: absolute;
          top: 50%;
          right: 20px;
          transform: translate3d(0, -50%, 0);
        }
      }
      .phone-box {
        position: relative;
        .el-input__inner {
          padding-left: 60px;
        }
        i {
          line-height: 60px;
          font-size: 20px;
          color: #000;
          position: absolute;
          bottom: 0;
          left: 20px;
        }
      }
    }
    .sb-bt::v-deep {
      width: 740px;
      margin-top: 60px;
      .ui-lk-bt {
        width: 100%;
        height: 60px;
        line-height: 55px;
        background: #f4cc25;
        font-size: 20px;
        font-family: AlibabaPuHuiTiM;
        color: #000;
        z-index: 10;
        position: relative;
        border-radius: 8px;
        border: 2px solid #000000;
        box-sizing: border-box;
      }
      .ui-lk-bt-i {
        width: 100%;
        height: 60px;
        box-sizing: border-box;
        background: #fff;
        border-radius: 8px;
        border: 2px solid #231815;
        position: absolute;
        z-index: 9;
        top: 9px;
        left: 0;
      }
      &.ui-lk-bt-box:active .ui-lk-bt,
      .ui-lk-bt:active {
        top: 4px;
      }
    }
    .text-policy {
      height: 26px;
      font-size: 16px;
      font-family: AlibabaPuHuiTiR;
      color: #000000;
      line-height: 26px;
      display: flex;
      align-items: center;
      margin-top: 20px;
      i {
        width: 18px;
        height: 18px;
        background: url("../assets/img/create/icon-n.png") no-repeat center;
        background-size: 100%;
        margin-right: 5px;
        cursor: pointer;
        &.active {
          background: url("../assets/img/create/icon-y.png") no-repeat center;
          background-size: 100%;
        }
      }
      span {
        color: #000;
        font-weight: bold;
        margin: 0 5px;
      }
    }
  }
}
</style>
