<template>
  <div id="app">
    <Head></Head>
    <keep-alive>
      <router-view :key="$route.fullPath" />
    </keep-alive>
    <Bottom></Bottom>
  </div>
</template>
<script>
import Head from "./components/Head.vue";
import Bottom from "./components/Bottom.vue";
export default {
  name: "App",
  components: {
    Bottom,
    Head,
  },
  mounted() {
    setTimeout(() => {
      this.$nextTick(() => {
        const { id } = this.$route.query;
        if (id) {
          const idel = document.querySelector("#" + id);
          if (idel) {
            console.log(
              "idel.getBoundingClientRect().top",
              idel.getBoundingClientRect().top
            );
            document.documentElement.scrollTop =
              idel.getBoundingClientRect().top;
          }
        }
      });
    }, 2000);
  },
};
</script>
<style lang="scss" scoped></style>
>
