import { request } from "@/common/request";
import url from "@/common/config/url";

// 提交心愿/故事
export function uploadFile(data) {
  return request({
    url: url.uploadFile,
    method: "post",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data,
  });
}
