<template>
  <div class="ui-lk-bt-box">
    <button @click="submit" class="ui-lk-bt">{{ msg }}</button>
    <i class="ui-lk-bt-i"></i>
  </div>
</template>

<script>
export default {
  name: "LkBt",
  props: {
    msg: String,
  },
  methods: {
    submit() {
      this.$emit("submit");
    },
  },
};
</script>
