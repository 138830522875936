<template>
  <div class="page-home">
    <!-- banner -->
    <BannerSwiper :banner="bannerList" class="section-banner"></BannerSwiper>
    <!-- what -->
    <div class="section-what">
      <div class="content-box">
        <h1 class="title">What is Lucky Box?</h1>
        <p class="text">
          The Core Mission of Lucky Box is creating to surprise and delight the
          world!<br />We use our intelligent vending machines to provide players
          with valuable and amazing surprises!
        </p>
        <img class="step" src="../assets/img/home/icon-what-step.png" alt="" />
        <img
          :class="{ totop: isLuckytop }"
          class="icon-lucky"
          src="../assets/img/home/icon-lucky.png"
          alt=""
          @click="onLucky"
        />
        <img
          class="icon-redeem"
          data-role="redeem"
          :class="{ fix: ifRedeemFix }"
          src="../assets/img/home/icon-redeem.png"
          alt=""
          @click="goPageByName('redeemprizes')"
        />
      </div>
    </div>
    <!-- share -->
    <div class="section-share">
      <div class="an-share"></div>
      <div class="content-box">
        <p>Share Your Secret Story On Our<br />Answer Card!</p>
        <div class="ui-lk-bt-box share-bt">
          <button @click="onBtShare" class="ui-lk-bt">Share Now</button>
          <i class="ui-lk-bt-i"></i>
        </div>
        <img
          class="img-left"
          src="../assets/img/home/img-xiaoh-left.png"
          alt=""
        />
        <img
          class="img-right"
          src="../assets/img/home/img-xiaoh-right.png"
          alt=""
        />
      </div>
    </div>
    <!-- winner -->
    <div class="section-winner" id="winner">
      <div class="content-box">
        <h1 class="title">Previous Winners</h1>
        <div class="winner-box">
          <el-carousel
            ref="carouselwinner"
            :autoplay="autoplay"
            :loop="loop"
            type="card"
          >
            <el-carousel-item v-for="(item, index) in winnerList" :key="index">
              <div class="winner-item">
                <div class="img-bg" :class="'img-bg-' + (index % 3)">
                  <img class="img-user" :src="item.image" alt="" />
                </div>
                <p class="name">{{ item.text }}</p>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="winner-choose">
          <span @click="prev" class="choose-l"></span>
          <span @click="next" class="choose-r"></span>
        </div>
        <div class="ui-lk-bt-box winner-bt">
          <button @click="goPageByName('store')" class="ui-lk-bt">
            FIND A LUCKY BOX VENDING<br />MACHINE NEAR ME
          </button>
          <i class="ui-lk-bt-i"></i>
        </div>
        <i class="icon-line"></i>
        <i class="icon-line-2"></i>
        <p class="text-prizes" id="wish">
          What Prizes Do You Want To See In<br />Lucky Box? Let Us Know!
        </p>
        <div class="ui-lk-bt-box prizes-bt">
          <button @click="onBtWish" class="ui-lk-bt">I WISH FOR...</button>
          <i class="ui-lk-bt-i"></i>
        </div>
      </div>
    </div>
    <LkDialogForm
      ref="LkDialogForm"
      @submit="onSubmit"
      @close="onClose"
    ></LkDialogForm>
    <LkDialog ref="LkDialog"></LkDialog>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";
import BannerSwiper from "@/components/BannerSwiper.vue";
import LkDialogForm from "@/components/LkDialogForm.vue";
import LkDialog from "@/components/LkDialog.vue";
import { submitText, getMaterial } from "@/api/submission";
import { mapState } from "vuex";
import { goPageByName } from "@/util/link";
import store from "@/store";
export default {
  name: "HomeView",
  components: {
    BannerSwiper,
    LkDialogForm,
    LkDialog,
  },
  data() {
    return {
      autoplay: true,
      loop: true,
      isLuckytop: false,
      desc: {},
      bannerList: [],
      winnerList: [],
      ifRedeemFix: false,
    };
  },
  computed: {
    ...mapState(["isLogin", "pcIndexBanner"]),
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll);
    if (!this.pcIndexBanner.length) {
      this.getMaterial();
    } else {
      this.bannerList = this.pcIndexBanner;
    }
  },
  methods: {
    prev() {
      this.$refs.carouselwinner.prev();
    },
    next() {
      this.$refs.carouselwinner.next();
    },
    onScroll() {
      const h = (window.screen.height / 4) * 3;
      const t =
        document.querySelector(".section-what") &&
        document.querySelector(".section-what").offsetTop;
      const scop = document.documentElement.scrollTop;
      this.isLuckytop = scop > t - h;
    },
    onBtShare() {
      if (!this.isLogin) {
        goPageByName({
          name: "logon",
          query: {
            redireName: "home",
          },
        });
        return;
      }
      console.log(this.desc);
      this.$refs.LkDialogForm.show({
        title: "Share Your Secret Story on our Answer Card!",
        bt: "Share",
        type: "share",
        desc: this.desc.share,
      });
    },
    onBtWish() {
      if (!this.isLogin) {
        goPageByName({
          name: "logon",
          query: {
            redireName: "home",
          },
        });
        return;
      }
      this.$refs.LkDialogForm.show({
        title: `What Prizes do you want to see in Lucky Box? Let us know!`,
        bt: "I wish for...",
        type: "wish",
        desc: this.desc.wish,
      });
    },
    async onSubmit(obj) {
      if (!obj.desc) {
        this.$message({
          message: "Please write some words before submitting.",
          type: "warning",
        });
        return;
      }
      const type = obj.type === "share" ? 0 : 1;
      const res = await submitText({
        content: obj.desc,
        type,
      });
      if (res.code === 0) {
        this.$refs.LkDialogForm.close();
        this.$refs.LkDialog.show({
          title: `Thanks for your share!`,
          text: "Your information has been submitted successfully.",
          bt: "OK",
        });
      } else {
        this.$toast(res.msg);
      }
    },
    onClose(obj) {
      console.log(obj);
      this.desc[obj.type] = obj.desc;
    },
    async getMaterial() {
      const res = await getMaterial({
        tags: "pcIndexBanner,previousWinners",
      });
      console.log("res", res);
      this.bannerList = res.data.pcIndexBanner;
      this.winnerList = res.data.previousWinners;
      store.commit("setpcIndexBanner", this.bannerList);
    },
    goPageByName(name) {
      goPageByName({
        name,
      });
    },
    onLucky() {
      window.location.href = "#winner";
    },
  },
};
</script>
<style lang="scss" scoped>
.page-home {
  width: 100%;
  overflow: hidden;
  .section-banner {
    width: 100%;
    position: relative;
    &::after {
      width: 2016px;
      height: 44px;
      content: "";
      background: url("../assets/img/home/bg-winner-bottom.png") no-repeat
        center bottom;
      background-size: 2016px 44px;
      position: absolute;
      bottom: -1px;
      left: 0;
      animation: line-b-bolang1 20s linear infinite;
      z-index: 9;
    }
    &::before {
      width: 2016px;
      height: 44px;
      content: "";
      background: url("../assets/img/home/bg-winner-bottom.png") no-repeat
        center bottom;
      background-size: 2016px 44px;
      position: absolute;
      bottom: -1px;
      left: 2016px;
      animation: line-b-bolang2 20s linear infinite;
      z-index: 9;
    }
  }
  .section-what {
    background: #231815;
    .content-box {
      width: 1190px;
      margin: 0 auto;
      padding: 156px 0 116px;
      box-sizing: border-box;
      text-align: center;
      position: relative;
      .title {
        height: 88px;
        font-size: 64px;
        font-family: AlibabaPuHuiTiH;
        color: #ffffff;
        line-height: 88px;
      }
      .text {
        font-size: 24px;
        font-family: AlibabaPuHuiTiR;
        color: #ffffff;
        line-height: 36px;
        margin-top: 40px;
        word-spacing: -2px;
      }
      .step {
        width: 1104px;
        height: 246px;
        margin-top: 100px;
      }
      .icon-lucky {
        width: 224px;
        height: 224px;
        position: absolute;
        top: 0;
        left: -84px;
        animation: anlucky 10s linear infinite;
        transition: all 2s linear;
        z-index: 99;
        cursor: pointer;
        &.totop {
          top: -212px;
        }
      }
      .icon-redeem {
        width: 164px;
        height: 164px;
        position: fixed;
        bottom: 70px;
        right: 38px;
        z-index: 999;
        cursor: pointer;
      }
    }
  }
  .section-share {
    width: 100%;
    padding: 173px 0 77px;
    background: linear-gradient(241deg, #edff39 0%, #72fef8 100%);
    text-align: center;
    position: relative;
    .an-share {
      width: 100%;
      height: 100px;
      position: absolute;
      top: 0;
      left: 0;
      overflow: hidden;
      z-index: 99;
      &:after {
        width: 3032px;
        height: 50px;
        content: "";
        background: url("../assets/img/home/bg-share.png") repeat center;
        background-size: 1516px 50px;
        position: absolute;
        top: 0;
        left: 0;
        animation: bgshare1 12s linear infinite;
      }
      &:before {
        width: 3032px;
        height: 50px;
        content: "";
        background: url("../assets/img/home/bg-share.png") repeat center;
        background-size: 1516px 50px;
        position: absolute;
        top: 0;
        left: 1516px;
        animation: bgshare2 12s linear infinite;
      }
    }
    .content-box {
      width: 1190px;
      margin: 0 auto;
      position: relative;
    }
    p {
      font-size: 64px;
      font-family: AlibabaPuHuiTiH;
      color: #000000;
      line-height: 88px;
    }
    .share-bt {
      margin-top: 56px;
    }
    .img-left {
      position: absolute;
      top: -290px;
      right: -160px;
      width: 347px;
      height: 289px;
      z-index: 99;
    }
    .img-right {
      position: absolute;
      bottom: -290px;
      left: -120px;
      width: 507px;
      height: 453px;
      z-index: 99;
    }
  }
  .section-winner {
    width: 100%;
    height: 2141px;
    background: url("../assets/img/home/bg-winner.png") no-repeat center;
    background-size: 1920px 2141px;
    position: relative;
    text-align: center;
    overflow: hidden;
    &::after {
      width: 100%;
      height: 40px;
      content: "";
      background: url("../assets/img/home/bg-winner-bottom.png") no-repeat
        center;
      background-size: 1920px 100%;
      position: absolute;
      bottom: -1px;
      left: 0;
      animation: winner-line1 12s linear infinite;
    }
    &::before {
      width: 100%;
      height: 40px;
      content: "";
      background: url("../assets/img/home/bg-winner-bottom.png") no-repeat
        center;
      background-size: 1920px 100%;
      position: absolute;
      bottom: -1px;
      left: 100%;
      animation: winner-line2 12s linear infinite;
    }
    .content-box {
      width: 1190px;
      margin: 0 auto;
      padding-top: 208px;
    }
    .title {
      font-size: 64px;
      font-family: AlibabaPuHuiTiH;
      color: #ffffff;
      line-height: 88px;
    }
    .winner-box::v-deep {
      margin-top: 54px;
      font-size: 0;
      .winner-item {
        display: inline-block;
        margin-top: 42px;
        margin-right: 34px;
        position: relative;
        vertical-align: top;
        &.active {
          margin-top: 0;
        }
        &:last-child {
          margin-right: 0;
        }
        .img-bg {
          width: 368px;
          height: 460px;
          background: url("../assets/img/home/bg-winner-1.png") no-repeat center;
          background-size: 100% 100%;
          position: relative;
          margin: 0 auto;
          &.img-bg-0 {
            background: url("../assets/img/home/bg-winner-1.png") no-repeat
              center;
            background-size: 100% 100%;
          }
          &.img-bg-1 {
            background: url("../assets/img/home/bg-winner-2.png") no-repeat
              center;
            background-size: 100% 100%;
          }
          &.img-bg-2 {
            background: url("../assets/img/home/bg-winner-3.png") no-repeat
              center;
            background-size: 100% 100%;
          }
        }
        .img-user {
          width: 280px;
          height: 328px;
          border-radius: 8px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate3d(-50%, -50%, 0);
          object-fit: cover;
        }
        .name {
          font-size: 32px;
          font-family: AlibabaPuHuiTiM;
          color: #000000;
          line-height: 44px;
          margin-top: 40px;
        }
      }
      .el-carousel__container {
        height: 600px;
      }
      .el-carousel__mask {
        display: none;
      }
      .el-carousel__indicators--outside {
        display: none;
      }
    }
    .winner-choose {
      margin-top: 40px;
      padding-right: 95px;
      text-align: right;
      span {
        width: 89px;
        height: 74px;
        margin-right: 31px;
        display: inline-block;
        cursor: pointer;
        &:last-child {
          margin-right: 0;
        }
        &.choose-l {
          background: url("../assets/img/home/icon-winner-l.png") no-repeat
            center;
          background-size: 100% 100%;
          &:active {
            background: url("../assets/img/home/icon-winner-l-a.png") no-repeat
              center bottom;
            background-size: 100% 66px;
          }
        }
        &.choose-r {
          background: url("../assets/img/home/icon-winner-r.png") no-repeat
            center;
          background-size: 100% 100%;
          &:active {
            background: url("../assets/img/home/icon-winner-r-a.png") no-repeat
              center bottom;
            background-size: 100% 66px;
          }
        }
      }
    }
    .winner-bt {
      width: 682px;
      margin-top: 40px;
      .ui-lk-bt {
        height: 123px;
        color: #ffe33e;
        line-height: 44px;
      }
      .ui-lk-bt-i {
        height: 123px;
        background: linear-gradient(90deg, #ffa800 0%, #de0900 100%);
      }
    }
    .icon-line {
      width: 100%;
      height: 71px;
      background: url("../assets/img/home/bg-lk-line.png") no-repeat center;
      background-size: 1920px 71px;
      position: absolute;
      top: 1378px;
      left: 0;
      transform: rotateZ(-2deg);
      animation: line1 15s linear infinite;
    }
    .icon-line-2 {
      width: 100%;
      height: 71px;
      background: url("../assets/img/home/bg-lk-line.png") no-repeat center;
      background-size: 1920px 71px;
      position: absolute;
      top: 1311px;
      left: -100%;
      transform: rotateZ(-2deg);
      animation: line2 15s linear infinite;
    }
    .text-prizes {
      margin-top: 380px;
      font-size: 60px;
      font-family: AlibabaPuHuiTiH;
      color: #000000;
      line-height: 88px;
    }
    .prizes-bt {
      width: 357px;
      margin-top: 56px;
      .ui-lk-bt {
        color: #ffe33e;
      }
      .ui-lk-bt-i {
        background: linear-gradient(90deg, #ffc234 0%, #ff5418 100%);
      }
    }
  }
}

@keyframes bgshare1 {
  0% {
    left: 0;
  }
  100% {
    left: -1516px;
  }
}
@keyframes bgshare2 {
  0% {
    left: 1516px;
  }
  100% {
    left: 0;
  }
}

@keyframes line1 {
  0% {
    left: 0;
  }
  100% {
    left: -100%;
    top: 1443px;
  }
}
@keyframes line2 {
  0% {
    left: 99%;
  }
  100% {
    left: -3px;
    top: 1378px;
  }
}
@keyframes anlucky {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}

@keyframes winner-line1 {
  0% {
    left: 0;
  }
  100% {
    left: -100%;
  }
}
@keyframes winner-line2 {
  0% {
    left: 100%;
  }
  100% {
    left: 0;
  }
}
</style>
