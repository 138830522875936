<template>
  <div class="page-luckybox">
    <!-- banner -->
    <BannerSwiper :banner="bannerList" class="section-banner"></BannerSwiper>
    <!-- play -->
    <div class="section-play">
      <div class="content-box">
        <div class="info-box">
          <el-carousel :autoplay="autoplay" trigger="click">
            <el-carousel-item v-for="(item, index) in playList" :key="index">
              <div class="info-item">
                <img :src="item.image" alt="" />
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>
    <!-- surprises -->
    <div class="section-surprises section-box">
      <div class="content-box">
        <h1 class="title">Let Lucky Box bring you daily<br />surprises!</h1>
        <p class="text">
          Lucky Box will give you a piece of surprise and joyful experience in
          your daily life!<br />
          Are you having a happy day? Feeling lucky? Feeling down? Is it a
          birthday? An anniversary day?
        </p>
        <p class="tips">TEST YOUR LUCKY DAY!</p>
        <div class="img-day">
          <img src="../assets/img/box/img-day.png" alt="" />
        </div>
      </div>
    </div>
    <!-- prize -->
    <div class="section-prize section-box">
      <i class="icon-bl"></i>
      <div class="content-box">
        <i class="icon-star"></i>
        <p class="title-prize">Redeem My Prize</p>
        <LkBt @submit="onRedeemBt" class="redeem-bt" :msg="'REDEEM'"></LkBt>
        <p class="title-find">Find a Lucky Box Vending Machine<br />Near Me</p>
        <LkBt @submit="onGoBt" class="go-bt" :msg="'GO'"></LkBt>
      </div>
    </div>
  </div>
</template>
<script>
import LkBt from "../components/LkBt.vue";
import BannerSwiper from "@/components/BannerSwiper.vue";
import { goPageByName } from "@/util/link";
import { mapState } from "vuex";
import { getMaterial } from "@/api/submission";
import store from "@/store";
export default {
  name: "BoxView",
  data() {
    return {
      autoplay: true,
      bannerList: [],
      playList: [],
    };
  },
  components: {
    LkBt,
    BannerSwiper,
  },
  computed: {
    ...mapState(["isLogin", "pcWhatInBoxBanner"]),
  },
  mounted() {
    console.log(this.$route.query);
    this.getMaterial();
    if (!this.pcWhatInBoxBanner.length) {
      this.getMaterial();
    } else {
      this.bannerList = this.pcWhatInBoxBanner;
    }
  },
  methods: {
    onRedeemBt() {
      if (this.isLogin) {
        goPageByName({ name: "redeemprizes" });
      } else {
        goPageByName({ name: "logon" });
      }
    },
    onGoBt() {
      goPageByName({ name: "store" });
    },
    async getMaterial() {
      const res = await getMaterial({
        tags: "pcWhatInBoxBanner,howToPlay",
      });
      console.log("res", res);
      this.bannerList = res.data.pcWhatInBoxBanner;
      this.playList = res.data.howToPlay;
      store.commit("pcWhatInBoxBanner", this.bannerList);
    },
  },
};
</script>
<style lang="scss" scoped>
.page-luckybox {
  width: 100%;
  overflow: hidden;
  .section-banner {
    width: 100%;
    position: relative;
    &::after {
      width: 2375px;
      height: 89px;
      content: "";
      background: url("../assets/img/home/bg-lk-line.png") no-repeat center;
      background-size: 2375px 89px;
      position: absolute;
      left: -2px;
      bottom: -69px;
      z-index: 9;
    }
    .content-box {
      font-size: 0;
    }
    img {
      width: 100%;
      height: auto;
    }
  }
  .section-play {
    width: 100%;
    height: 823px;
    position: relative;
    background: url("../assets/img/box/bg-play.jpg") no-repeat center;
    background-size: 1920px 823px;
    &::after {
      width: 2016px;
      height: 44px;
      content: "";
      background: url("../assets/img/home/bg-winner-bottom.png") no-repeat
        center bottom;
      background-size: 2016px 44px;
      position: absolute;
      bottom: -1px;
      left: 0;
      animation: line-b-bolang1 20s linear infinite;
      z-index: 9;
    }
    &::before {
      width: 2016px;
      height: 44px;
      content: "";
      background: url("../assets/img/home/bg-winner-bottom.png") no-repeat
        center bottom;
      background-size: 2016px 44px;
      position: absolute;
      bottom: -1px;
      left: 2016px;
      animation: line-b-bolang2 20s linear infinite;
      z-index: 9;
    }
    .content-box {
      width: 1190px;
      margin: 0 auto;
      padding-top: 135px;
      .info-box::v-deep {
        width: 600px;
        height: 488px;
        .el-carousel__container {
          height: 488px;
        }
        .info-item img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        .el-carousel__button {
          width: 10px;
          height: 10px;
          background: #fff;
          border-radius: 50%;
          margin-right: 8px;
        }
        .el-carousel__indicator--horizontal {
          padding: 0;
          padding-bottom: 18px;
        }
      }
    }
  }
  .section-surprises {
    background: #231815;
    .content-box {
      padding-top: 127px;
      padding-bottom: 34px;
    }
    .title {
      font-size: 64px;
      font-family: AlibabaPuHuiTiH;
      color: #ffffff;
      line-height: 88px;
    }
    .text {
      font-size: 24px;
      font-family: AlibabaPuHuiTiR;
      color: #ffffff;
      line-height: 36px;
      margin-top: 40px;
      word-spacing: -3px;
    }
    .tips {
      font-size: 40px;
      font-family: AlibabaPuHuiTiB;
      color: #ffffff;
      line-height: 36px;
      margin-top: 40px;
    }
    .img-day {
      width: 1498px;
      height: 323px;
      margin: 100px 0 0 -154px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .section-prize {
    width: 100%;
    height: 1682px;
    background: url("../assets/img/box/bg-prize.png") no-repeat center;
    background-size: 1920px 1682px;
    .icon-bl {
      width: 100%;
      height: 149px;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      background: url("../assets/img/box/bg-prize-line.png") no-repeat center;
      background-size: 1920px 100%;
    }
    &::after {
      width: 2016px;
      height: 44px;
      content: "";
      background: url("../assets/img/home/bg-winner-bottom.png") no-repeat
        center bottom;
      background-size: 2016px 44px;
      position: absolute;
      bottom: -1px;
      left: 0;
      animation: line-b-bolang1 20s linear infinite;
      z-index: 9;
    }
    &::before {
      width: 2016px;
      height: 44px;
      content: "";
      background: url("../assets/img/home/bg-winner-bottom.png") no-repeat
        center bottom;
      background-size: 2016px 44px;
      position: absolute;
      bottom: -1px;
      left: 2016px;
      animation: line-b-bolang2 20s linear infinite;
      z-index: 9;
    }
    .content-box {
      position: relative;
      padding-top: 251px;
    }
    .icon-star {
      width: 127px;
      height: 132px;
      background: url("../assets/img/box/icon-star.png") no-repeat center;
      background-size: 100%;
      position: absolute;
      top: 0;
      right: -50px;
      z-index: 9;
      animation: an-star 12s linear infinite;
    }
    .title-prize {
      font-size: 64px;
      font-family: AlibabaPuHuiTiH;
      color: #000000;
      line-height: 88px;
    }
    .redeem-bt::v-deep {
      margin-top: 56px;
      .ui-lk-bt {
        color: #55ff52;
      }
      .ui-lk-bt-i {
        background: linear-gradient(90deg, #d8f000 0%, #7aff00 100%);
      }
    }
    .title-find {
      margin-top: 459px;
      font-size: 64px;
      font-family: AlibabaPuHuiTiH;
      color: #000000;
      line-height: 88px;
    }
    .go-bt::v-deep {
      margin-top: 56px;
      .ui-lk-bt {
        color: #ffe33e;
      }
      .ui-lk-bt-i {
        background: linear-gradient(90deg, #ffa800 0%, #de0900 100%);
      }
    }
  }
}

@keyframes an-star {
  100% {
    transform: rotate(360deg);
  }
}
</style>
