<template>
  <div class="page-logon">
    <!-- banner -->
    <div class="section-banner section-box"></div>
    <!-- form -->
    <div class="section-form section-box">
      <div class="content-box">
        <div class="form-box">
          <div class="input-box" :class="{ error: error.email }">
            <label for="email">Email</label>
            <input
              v-model="form.email"
              @blur="onBlur('email')"
              @focus="onFocus('email')"
              placeholder="email"
              type="text"
              id="email"
            />
            <p class="text-error">{{ rules.email }}</p>
          </div>
          <div class="input-box" :class="{ error: error.password }">
            <label for="password">Password</label>
            <input
              v-model="form.password"
              @blur="onBlur('password')"
              @focus="onFocus('password')"
              placeholder="password"
              type="password"
              id="password"
            />
            <p class="text-error">{{ rules.password }}</p>
          </div>
          <LkBt @submit="onLogin" class="logon-bt" :msg="'LOG IN'"></LkBt>
          <p class="tips">
            New Lucky Member?
            <span @click="goPageByName('create')">Sign up</span>
            <br />
            <span @click="onForgot">Forgot password?</span>
          </p>
        </div>
        <div class="order-type">
          <!-- <p class="text">Or log on with your social media account</p>
          <div class="bt-box">
            <button class="bt-fb"><i></i><span>Facebook</span></button>
            <button class="bt-gg"><i></i><span>Google</span></button>
          </div> -->
          <!-- <p class="tips">
            By clicking any of the social login buttons you agree to the terms
            of privacy policy described <span @click="goPageHelp">here</span>
          </p> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LkBt from "../components/LkBt.vue";
import { loginByEmailPwd, getInfo, forgetPwd } from "@/api/user";
import { goPageByName, goReplaceByName } from "@/util/link";
import store from "@/store";
import { mapState } from "vuex";
export default {
  name: "LogonView",
  components: {
    LkBt,
  },
  computed: {
    ...mapState(["isLogin"]),
  },
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
      rules: {
        email: "Please enter email",
        password: "Please enter password",
      },
      error: {
        email: false,
        password: false,
      },
    };
  },
  mounted() {
    if (this.isLogin) {
      goPageByName({
        name: "membership",
      });
    }
    console.log(this.$route.query);
  },
  methods: {
    goPageByName(name) {
      goPageByName({ name });
    },
    goPageHelp() {
      goPageByName({ name: "help", query: { type: "terms" } });
    },
    onBlur(value) {
      if (!this.form[value]) {
        this.error[value] = true;
      }
    },
    onFocus(value) {
      this.error[value] = false;
    },
    async onLogin() {
      if (!this.form.email && !this.form.password) {
        this.error.password = true;
        this.error.email = true;
        return;
      }
      if (!this.form.email) {
        this.error.email = true;
        return;
      }
      if (!this.form.password) {
        this.error.password = true;
        return;
      }
      const res = await loginByEmailPwd({
        email: this.form.email,
        password: this.form.password,
      });
      if (res.code === 0) {
        window.localStorage.setItem("authToken", res.data.authToken);
        window.localStorage.setItem("cardNo", res.data.cardNo);
        window.localStorage.setItem("email", res.data.email);
        window.localStorage.setItem("userId", res.data.userId);
        store.commit("setLoginState", true);
        await this.getInfo();
        if (this.redireUrl) {
          window.location.replace(this.redireUrl);
        } else if (this.redireName == "membership") {
          goReplaceByName({ name: this.redireName });
        } else {
          goReplaceByName({ name: "home" });
        }
      } else {
        this.$message(res.msg);
      }
    },
    // 获取个人信息
    async getInfo() {
      const res = await getInfo();
      console.log(res);
      if (res.code === 0) {
        const name = {};
        name.firstName = res.data.firstName;
        name.lastName = res.data.lastName;
        window.localStorage.setItem("lastName", res.data.lastName);
        window.localStorage.setItem("firstName", res.data.firstName);
        store.commit("setNameState", name);
      }
    },
    async onForgot() {
      if (!/@.*/.test(this.form.email)) {
        this.$message("Please enter the correct Email Address");
      } else {
        const res = await forgetPwd({
          email: this.form.email,
        });
        if (res.code === 0) {
          this.$message("Password reset link sent to your email successfully!");
        } else {
          this.$message(res.msg);
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.page-logon {
  width: 100%;
  .section-banner {
    width: 100%;
    height: 360px;
    position: relative;
    background: url("../assets/img/logon/banner.jpg") no-repeat center;
    background-size: 1920px 360px;
  }
  .section-form {
    background: #f4f5f6;
    .content-box {
      width: 740px;
      padding: 45px 0 240px;
      text-align: left;
    }
    .form-box {
      .input-box {
        margin-top: 20px;
        position: relative;
        &.error {
          input {
            border: 1px solid #ff0000;
          }
          .text-error {
            display: block;
          }
        }
        label {
          font-size: 20px;
          font-family: AlibabaPuHuiTiR;
          color: #000000;
          line-height: 67px;
          display: inline-block;
          cursor: pointer;
        }
        input {
          width: 740px;
          height: 60px;
          padding: 20px 0 20px 10px;
          box-sizing: border-box;
          font-size: 20px;
          border-radius: 8px;
          border: 1px solid #979797;
        }
        .text-error {
          display: none;
          position: absolute;
          left: 10px;
          bottom: -36px;
          line-height: 26px;
          margin-top: 10px;
          font-size: 18px;
          font-family: AlibabaPuHuiTiR;
          color: #ff0000;
        }
      }
      .tips {
        padding: 40px 0;
        font-size: 20px;
        font-family: AlibabaPuHuiTiM;
        color: #000000;
        line-height: 27px;
        border-bottom: 1px dashed #a7a7a8;
        text-align: center;
        span {
          color: #ff521f;
          cursor: pointer;
        }
      }
    }
    .logon-bt::v-deep {
      width: 740px;
      margin-top: 99px;
      .ui-lk-bt {
        width: 100%;
        height: 60px;
        line-height: 55px;
        background: #f4cc25;
        font-size: 20px;
        font-family: AlibabaPuHuiTiM;
        color: #000;
        z-index: 10;
        position: relative;
        border-radius: 8px;
        border: 2px solid #000000;
        box-sizing: border-box;
      }
      .ui-lk-bt-i {
        width: 100%;
        height: 60px;
        line-height: 60px;
        box-sizing: border-box;
        background: #fff;
        border-radius: 8px;
        border: 2px solid #231815;
        position: absolute;
        z-index: 9;
        top: 9px;
        left: 0;
      }
      &.ui-lk-bt-box:active .ui-lk-bt,
      .ui-lk-bt:active {
        top: 4px;
      }
    }
    .order-type {
      .text {
        font-size: 20px;
        font-family: AlibabaPuHuiTiM;
        color: #000000;
        line-height: 27px;
        margin-top: 40px;
      }
      .bt-box {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        button {
          width: 357px;
          height: 60px;
          background: #ffffff;
          border-radius: 8px;
          border: 1px solid #979797;
          font-size: 16px;
          font-family: AlibabaPuHuiTiM;
          color: #000000;
          display: flex;
          justify-content: center;
          align-items: center;
          i {
            width: 18px;
            height: 18px;
            margin-right: 8px;
          }
          &.bt-fb i {
            background: url("../assets/img/logon/icon-fb.png") no-repeat center;
            background-size: 100%;
          }
          &.bt-gg i {
            background: url("../assets/img/logon/icon-gg.png") no-repeat center;
            background-size: 100%;
          }
        }
      }
      .tips {
        font-size: 16px;
        font-family: AlibabaPuHuiTiR;
        color: #666666;
        line-height: 22px;
        margin-top: 20px;
        span {
          color: #1977f2;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
