const firstName = [
  {
    required: true,
    pattern: /^[A-Za-z]{2,}$/,
    trigger: "blur",
    message: "Please enter the correct First Name",
  },
];

const lastName = [
  {
    required: true,
    pattern: /^[A-Za-z]{2,}$/,
    trigger: "blur",
    message: "Please enter the correct Last Name",
  },
];

const email = [
  {
    required: true,
    pattern: /@.*/,
    trigger: "blur",
    message: "Please enter the correct Email Address",
  },
];

const password = [
  {
    trigger: "blur",
    required: true,
    pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,}$/,
    message:
      "password must be longer than six digits, cap sensitive and contain a combination of letters and numbers",
  },
];

const repassword = [
  {
    trigger: "blur",
    required: true,
    pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,}$/,
    message: "password does not match, please retry",
  },
];

const phone = [
  {
    required: true,
    pattern: /^\d{9}$/,
    trigger: "blur",
    message: "Please enter a valid phone number (only digits allowed)",
  },
];

const addressLine1 = [
  {
    pattern: /^.{10,}$/,
    trigger: "blur",
    message: "The address is too short, please add)",
  },
  {
    pattern: /[a-zA-Z]/g,
    trigger: "blur",
    message: "Please enter the correct Address",
  },
  {
    required: true,
    trigger: "blur",
    message: "Please enter the correct Address",
  },
];

const zipCode = [
  {
    required: true,
    pattern: /^\d{4}$/,
    trigger: "blur",
    message: "Please enter a valid postcode (only digits allowed)",
  },
];

export default {
  firstName,
  lastName,
  email,
  password,
  repassword,
  phone,
  addressLine1,
  zipCode,
};
