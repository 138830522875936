<template>
  <div class="banner">
    <div class="content-box">
      <el-carousel
        ref="carousel"
        :autoplay="autoplay"
        trigger="click"
        :loop="loop"
      >
        <el-carousel-item
          v-for="(item, index) in banner"
          :key="index"
          @click="onGo(item)"
        >
          <div class="img-box">
            <img :src="item.image" alt="" />
          </div>
        </el-carousel-item>
      </el-carousel>
      <div v-if="banner.length > 1" @click="prev" class="prev"></div>
      <div v-if="banner.length > 1" @click="next" class="next"></div>
    </div>
  </div>
</template>

<script>
import img from "../assets/img/home/banner-1.jpg";
export default {
  name: "LkBt",
  props: {
    banner: {
      type: Array,
      default: () => [{ image: img }],
    },
  },
  watch: {
    banner(newval) {
      if (newval.length > 1) this.autoplay = true;
    },
  },
  data() {
    return {
      autoplay: false,
      loop: true,
    };
  },
  mounted() {},
  methods: {
    onGo(item) {
      if (item.url) {
        window.location.href = item.url;
      }
    },
    prev() {
      console.log(this.$refs.carousel);
      this.$refs.carousel.prev();
    },
    next() {
      this.$refs.carousel.next();
    },
  },
};
</script>
<style lang="scss" scoped>
.banner::v-deep {
  width: 100%;
  position: relative;
  .content-box {
    font-size: 0;
    position: relative;
  }
  .img-box {
    width: 100%;
    min-height: 672px;
    height: 100%;
  }
  .el-carousel__container {
    height: 1085px;
  }
  .prev {
    width: 89px;
    height: 74px;
    background: url("../assets/img/home/icon-winner-l.png") no-repeat center;
    background-size: 100% 100%;
    position: absolute;
    bottom: 113px;
    right: 242px;
    cursor: pointer;
    z-index: 99;
    &:active {
      background: url("../assets/img/home/icon-winner-l-a.png") no-repeat center
        bottom;
      background-size: 100% 66px;
    }
  }
  .next {
    width: 89px;
    height: 74px;
    background: url("../assets/img/home/icon-winner-r.png") no-repeat center;
    background-size: 100% 100%;
    position: absolute;
    bottom: 113px;
    right: 121px;
    cursor: pointer;
    z-index: 99;
    &:active {
      background: url("../assets/img/home/icon-winner-r-a.png") no-repeat center
        bottom;
      background-size: 100% 66px;
    }
  }
}
</style>
