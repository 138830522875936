import { request } from "@/common/request";
import url from "@/common/config/url";

// 提交心愿/故事
export function submitText(data) {
  return request({
    url: url.submitText,
    method: "post",
    data,
  });
}

// 获取素材配置
export function getMaterial(params) {
  return request({
    url: url.getMaterial,
    method: "get",
    params,
    isNoNeedLoad: true,
  });
}
