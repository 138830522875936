<template>
  <div class="page-member-ship">
    <!-- banner -->
    <div class="section-banner section-box" v-if="type !== 'success'">
      <div class="content-box">
        <button @click="onLogOut" class="bt-logout">Log Out</button>
        <div>
          <h1 class="title">Hi {{ firstName }} {{ lastName }}!</h1>
          <p class="text-info text-id">
            <span><b>Member ID:</b>{{ cardNo }}<i></i></span>
          </p>
          <p class="text-info text-num">
            <span>Lucky Points:</span>{{ points }}
          </p>
        </div>
      </div>
    </div>
    <!-- success -->
    <div class="section-success section-box" v-if="type == 'success'">
      <div class="content-box">
        <button @click="onLogOut" class="bt-logout">Log Out</button>
        <div class="img-box">
          <img src="../assets/img/membership/icon-success.png" alt="" />
        </div>
        <h1 class="title">
          Congrats, {{ firstName }} {{ lastName }}!<br />You have successfully
          registered!
        </h1>
        <p class="text-info text-id">
          <span><b>Member ID:</b>{{ cardNo }}<i></i></span>
        </p>
        <p class="text-info text-num"><span>Lucky Points:</span>{{ points }}</p>
      </div>
    </div>
    <!-- info -->
    <div class="section-info section-box">
      <div class="content-box">
        <div class="info-item">
          <h2 class="title">Redeem Prizes</h2>
          <p class="text-path" @click="goPageByName('redeemprizes')">
            I’m winner! Redeem now >
          </p>
          <p class="text-path" @click="goPageByName('redeemhistory')">
            Redeem History >
          </p>
        </div>
        <div class="info-item">
          <h2 class="title">Luckybox Points</h2>
          <p class="text-path" @click="goPageByName('points')">
            View Details >
          </p>
        </div>
        <div class="info-item">
          <h2 class="title">Account Setting</h2>
          <p class="text-path" @click="goPageByName('details')">My Details ></p>
          <p class="text-path" @click="goPageByName('address')">
            Shipping Address >
          </p>
        </div>
      </div>
    </div>
    <!-- wish -->
    <div class="section-wish section-box">
      <div class="content-box">
        <img
          class="img-ren-1"
          src="../assets/img/membership/img-ren-1.png"
          alt=""
        />
        <img
          class="img-ren-2"
          src="../assets/img/membership/img-ren-2.png"
          alt=""
        />
        <h2 class="text-q">
          What Prizes do you want to see in Lucky<br />Box? Let us know!
        </h2>
        <LkBt class="bt-wish" @submit="onBtWish" :msg="'I WISH FOR...'"></LkBt>
      </div>
    </div>
    <!-- dialog -->
    <LkDialog ref="LkDialog"></LkDialog>
    <LkDialogForm
      ref="LkDialogForm"
      @submit="onSubmit"
      @close="onClose"
    ></LkDialogForm>
  </div>
</template>
<script>
import LkBt from "../components/LkBt.vue";
import LkDialog from "@/components/LkDialog.vue";
import LkDialogForm from "@/components/LkDialogForm.vue";
import { getInfo } from "@/api/user";
import { goPageByName } from "@/util/link";
import { submitText } from "@/api/submission";
import store from "@/store";
export default {
  name: "MemberShipView",
  data() {
    return {
      activeNames: [],
      desc: {},
      type: "",
      userId: "",
      firstName: "",
      lastName: "",
      points: "",
      cardNo: "",
    };
  },
  components: {
    LkBt,
    LkDialog,
    LkDialogForm,
  },
  mounted() {
    this.type = this.$route.query.type;
    this.userId = localStorage.getItem("userId");
    this.cardNo = localStorage.getItem("cardNo");
    console.log(this.$route.query);
    this.getInfo();
  },
  methods: {
    onBtWish() {
      this.$refs.LkDialogForm.show({
        title: `What Prizes do you want to see in Lucky Box? Let us know!`,
        bt: "I wish for...",
        type: "wish",
        desc: this.desc.wish,
      });
    },
    onClose(obj) {
      console.log(obj);
      this.desc[obj.type] = obj.desc;
    },
    // 获取个人信息
    async getInfo() {
      const res = await getInfo();
      console.log(res);
      if (res.code === 0) {
        this.firstName = res.data.firstName;
        this.lastName = res.data.lastName;
        this.points = res.data.points;
        const name = {
          firstName: res.data.firstName,
          lastName: res.data.lastName,
        };
        store.commit("setNameState", name);
      } else {
        this.$message(res.msg);
      }
    },
    goPageByName(name) {
      goPageByName({ name });
    },
    async onSubmit(obj) {
      console.log("obj", obj);
      if (!obj.desc) {
        this.$message("Please write some words before submitting.");
        return;
      }
      const res = await submitText({
        content: obj.desc,
        type: 1,
      });
      if (res.code === 0) {
        this.$refs.LkDialogForm.close();
        this.$refs.LkDialog.show({
          title: `Thanks for your share!`,
          text: "Your information has been submitted successfully.",
          bt: "OK",
        });
      } else {
        this.$message(res.msg);
      }
    },
    onLogOut() {
      store.commit("setLogOut");
      goPageByName({
        name: "logon",
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.page-member-ship {
  width: 100%;
  background: #f4f5f6;
  .section-banner {
    width: 100%;
    height: 360px;
    position: relative;
    background: url("../assets/img/membership/banner.jpg") no-repeat center;
    background-size: 1920px 360px;
    .content-box {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 360px;
    }
    .bt-logout {
      width: 200px;
      line-height: 60px;
      border: 1px solid #e8e8e8;
      border-radius: 8px;
      font-size: 16px;
      font-family: AlibabaPuHuiTiM;
      color: #fff;
      position: absolute;
      top: 20px;
      right: 0;
      &:hover {
        background: rgba(255, 255, 255, 0.3);
      }
    }
    .title {
      font-size: 56px;
      font-family: AlibabaPuHuiTiH;
      color: #fff;
      line-height: 77px;
    }
    .text-info {
      font-size: 24px;
      font-family: AlibabaPuHuiTiB;
      color: #fff;
      line-height: 33px;
      margin-top: 10px;
      text-align: left;
    }
    .text-id {
      margin-top: 18px;
      span {
        position: relative;
        b {
          font-weight: 400;
          // width: 180px;
          display: inline-block;
          // text-align: right;
        }
        i {
          width: 263px;
          height: 40px;
          position: absolute;
          top: 50%;
          right: -9px;
          transform: translate3d(100%, -50%, 0);
          background: url("../assets/img/membership/icon-idtips-w.png")
            no-repeat center;
          background-size: 100%;
        }
      }
    }
    .text-num {
      span {
        // width: 180px;
        display: inline-block;
        // text-align: right;
      }
    }
  }
  .section-success {
    padding: 27px 0 100px;
    position: relative;
    .content-box {
      position: relative;
    }
    .bt-logout {
      width: 200px;
      line-height: 60px;
      background: #e8e8e8;
      border-radius: 8px;
      font-size: 16px;
      font-family: AlibabaPuHuiTiM;
      color: #000000;
      position: absolute;
      top: 20px;
      right: 0;
      &:hover {
        background: #f4cc25;
      }
    }
    &::after {
      width: 761px;
      height: 0px;
      content: "";
      border-bottom: 1px dashed #a7a7a8;
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translate3d(-50%, 0, 0);
    }
    .img-box {
      width: 400px;
      height: 300px;
      margin: 0 auto;
    }
    .title {
      font-size: 40px;
      font-family: AlibabaPuHuiTiH;
      color: #000000;
      line-height: 55px;
      margin-top: 20px;
    }
    .text-info {
      font-size: 24px;
      font-family: AlibabaPuHuiTiB;
      color: #000000;
      line-height: 33px;
      margin-top: 10px;
      text-align: left;
    }
    .text-id {
      margin-top: 20px;
      span {
        position: relative;
        b {
          font-weight: 400;
          margin-left: 450px;
          display: inline-block;
          // text-align: right;
        }
        i {
          width: 263px;
          height: 40px;
          position: absolute;
          top: 50%;
          right: -9px;
          transform: translate3d(100%, -50%, 0);
          background: url("../assets/img/membership/icon-idtips-b.png")
            no-repeat center;
          background-size: 100%;
        }
      }
    }
    .text-num {
      span {
        margin-left: 450px;
        display: inline-block;
        // text-align: right;
      }
    }
  }
  .section-info {
    padding: 100px 0 130px;
    .content-box {
      display: flex;
    }
    .info-item {
      flex: 1;
      position: relative;
      &::after {
        content: "";
        width: 1px;
        height: 136px;
        background: rgba($color: #cbcbcb, $alpha: 0.5);
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate3d(0, -50%, 0);
      }
      &:last-child::after {
        display: none;
      }
      .title {
        font-size: 36px;
        font-family: AlibabaPuHuiTiH;
        color: #000000;
        line-height: 50px;
        margin-bottom: 40px;
      }
      .text-path {
        font-size: 20px;
        font-family: AlibabaPuHuiTiM;
        color: #000000;
        line-height: 27px;
        margin-top: 20px;
        cursor: pointer;
        &:hover {
          color: #ff5418;
        }
      }
    }
  }
  .section-wish {
    padding: 0 0 100px;
    .content-box {
      height: 388px;
      background: url("../assets/img/membership/bg-wish.jpg") no-repeat center;
      background-size: 100%;
      position: relative;
    }
    .img-ren-1 {
      width: 267px;
      height: 264px;
      position: absolute;
      top: 187px;
      left: -55px;
    }
    .img-ren-2 {
      width: 220px;
      height: 250px;
      position: absolute;
      top: -55px;
      right: -56px;
    }
    .text-q {
      font-size: 36px;
      font-family: AlibabaPuHuiTiH;
      color: #000000;
      line-height: 50px;
      padding-top: 100px;
    }
    .bt-wish::v-deep {
      width: 200px;
      margin-top: 40px;
      .ui-lk-bt {
        width: 100%;
        height: 60px;
        line-height: 55px;
        background: #f4cc25;
        font-size: 20px;
        font-family: AlibabaPuHuiTiM;
        color: #000;
        z-index: 10;
        position: relative;
        border-radius: 8px;
        border: 2px solid #000000;
        box-sizing: border-box;
      }
      .ui-lk-bt-i {
        width: 100%;
        height: 60px;
        box-sizing: border-box;
        background: #fff;
        border-radius: 8px;
        border: 2px solid #231815;
        position: absolute;
        z-index: 9;
        top: 9px;
        left: 0;
      }
      &.ui-lk-bt-box:active .ui-lk-bt,
      .ui-lk-bt:active {
        top: 4px;
      }
    }
  }
}
</style>
