import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import locale from "element-ui/lib/locale/lang/en";
import "element-ui/lib/theme-chalk/index.css";
import "lib-flexible/flexible"; //在入口文件引入然后运行
import { goPageByName } from "./util/link";
import * as VueGoogleMaps from "vue2-google-maps";
import "./assets/style/style.css";

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyA8F_T-iAKt1dBF9CvQzNSvveUXSXRQEtc",
    libraries: "places", // 可选项
  },
});
Vue.use(ElementUI, { locale });

let pathNameArr = [
  "address",
  "redeemhistory",
  "redeemprizes",
  "points",
  "details",
  "membership",
];

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
if (isMobile) {
  const hash = location.hash || "";
  const url = `${location.protocol}//m.luckybox.au/${hash}`;
  window.location.replace(url);
}

Vue.config.productionTip = false;
router.beforeEach((to, from, nex) => {
  let isGo = false;
  if (!store.state.isLogin) {
    for (let i = 0; i < pathNameArr.length; i++) {
      if (to.name == pathNameArr[i]) {
        goPageByName({
          name: "logon",
          query: {
            redireName: to.name,
          },
        });
        isGo = true;
      }
    }
    if (!isGo) {
      nex();
    }
  } else {
    nex();
  }
});

router.afterEach((to, from, nex) => {
  let navData = store.state.navData;
  const path = to.path;
  for (let i = 0; i < navData.length; i++) {
    navData[i].isActive = path == navData[i].path;
  }
  store.commit("seveNavData", navData);
  console.log("to", to, from, nex);
  window.scrollTo(0, 0);
  if (to.name == "home" || to.name == "box") {
    store.commit("setHeadFixed", false);
  } else {
    store.commit("setHeadFixed", true);
  }
});
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
