import router from "@/router/index";

export function goMembership(query = {}) {
  const authToken = window.localStorage.getItem("authToken");
  if (!authToken) {
    router.push({ name: "logon", query: { redirename: "membership" } });
  } else {
    router.push({ name: "membership", query });
  }
}
export function goPageByName({ name, query }) {
  console.log("goPageByName", router);
  const currentName =
    router.history && router.history.current && router.history.current.name;
  if (currentName === name) {
    return;
  }
  router.push({ name, query });
}
export function goReplaceByName({ name, query }) {
  const currentName =
    router.history && router.history.current && router.history.current.name;
  if (currentName === name) {
    return;
  }
  router.replace({ name, query });
}
