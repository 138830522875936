import { request } from "@/common/request";
import url from "@/common/config/url";

// 设置收货地址
export function setAddress(data) {
  return request({
    url: url.setAddress,
    method: "post",
    data,
  });
}

// 获取列表
export function getAddress(params, name) {
  return request({
    url: url.getAddress + name,
    method: "get",
    params,
    isNoNeedLoad: true,
  });
}

// 获取个人收货地址
export function myAddress(params) {
  return request({
    url: url.myAddress,
    method: "get",
    params,
  });
}
