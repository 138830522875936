const helpdata = {
  faq: {
    title: "FAQ’s",
    list: [
      {
        q: "Have a question? Ask Lucky! ",
        a: [
          "Hello, I’m Lucky! If you have any FAQs, I can answer most of them  below. <br>I find it way faster to search here first before submitting an enquiry. ",
          "If you need further assistance, I’ve put an option to submit an enquiry at the bottom of the screen, and I will help you as soon as possible!",
        ],
      },
      {
        q: "1.Who we are? WE ARE LUCKY BOX",
        a: [
          "Founded in 2019, Lucky Box Australia is the most innovative, interactive and joyful experience to our users. With our core mission is creating to surprise and delight the world. Lucky Box uses our special vending machines to provide users with valuable treasures!",
          "Find my updated statuses, new prizes and much much more on our social channels, come connect with me @luckyboxaustralia! ",
        ],
      },
      {
        q: "2.How to play Lucky Box?",
        a: [
          "You can purchase a  mystery Lucky Boxes at our <span id='store'>vending locations</span> and online to TEST YOUR LUCK and chase monthly large prizes ranging from high end devices to rare toys!",
          "I select all the prizes for everybody young and old to enjoy!Every month, I rotate new prizes and collaborate with different brands to bring the best experiences and happiness to you!",
        ],
      },
      {
        q: "3.What’s inside of a Lucky Box?",
        a: [
          "I put together magical Lucky Boxes and no matter who you are, I will deliver a surprise and delight!",
          "I pack over 100 different prizes monthly for you to enjoy and you may not even know it!",
          "Find more information about our prize on Instagram @ luckyboxAustralia",
        ],
      },
      {
        q: "4.Where I can find the Lucky Box vending machines?",
        a: [
          "Please see <span id='store'>our locations</span> to find me! Good Luck!",
          "Pssst - I have new machines coming regularly so keep an eye out near you!",
        ],
      },
      {
        q: "5.How often does Lucky Box have big prizes?",
        a: [
          "Every month! ",
          "we also prepare special prizes for different festivals. ",
        ],
      },
      {
        q: "6.How do I have a better chance of winning the big prize?",
        a: [
          "All the prizes I pack are randomised and everyone can win!",
          "A secret that I like to say is:",
          "1.Become a member and get secret hints and tips! –<span id='logon'> sign up </span>today!",
          "2.Keep following me on Instagram @luckyboxaustralia! I give hints and advice on there!",
          "3.Go go go! Keep testing your luck!",
        ],
      },
      {
        q: "7.How to keep up with the latest Lucky Box news?",
        a: [
          "I update regularly and connect with all my fans on Instagram @luckyboxaustralia",
        ],
      },
      {
        q: "8.After I redeem the prize order, how long will you send the package? How can I track my Lucky Box prize?",
        a: [
          "Normally when we receive your redemption order, we will send the prize to you within 14 days. The logistics time is uncertain depending on different regions.",
          "We will also send you an email with the link to the tracking number, you can check it any time.",
        ],
      },
    ],
  },
  prize: {
    title: "Prize Redeem Policy",
    list: [
      {
        q: "Prize Drawer Terms and Conditions of Claim",
        a: [
          "We at JB Equipment Service Pty Ltd (“Lucky Box,” “we,” “us,” or “our”) conducts a trade by way of providing mystery blind boxes for customers to purchase (“Customer,” “Entrant,” “you,” “your,” “them”). This Prize Drawer Terms and Conditions of Claim (“Policy”) describes the types of personal information that we collect, how we use it, how and when it may be shared, and the rights and choices you have with respect to the redemption and process of claiming a prize. It also explains how we communicate with you and how you can make requests or submit inquires to us about your information. Our goal is to help you understand all of this, as part of our commitment to maintaining your trust.",
          "This Policy also relates to the Prize submission and transfer as well as information collection and use practices of Lucky Box in connection with the Prizes redemption process on our online services www.luckybox.au or any other websites, pages, features, or content we own or operate (the “Sites”), our mobile/tablet applications (“Apps”), and any other Lucky Box products and services offered through any other websites and mobile applications that direct you to this Policy (collectively, including the Sites and the Apps, the “Services”).",
          "1.Lucky Box provides monthly larger prizes (the Prizes) that are displayed on the Lucky Box vending machines and on our Services",
          "2.Lucky Box’s use of particular brands as Prizes does not imply any affiliation with or any endorsement of such brands.",
          "3.The Prizes are non-exchangeable, non-transferrable and no cash alternatives will be offered.",
          "4.Lucky Box reserves the right in its absolute discretion to substitute any Prizes with other prizes of equal value if circumstances beyond our control makes it necessary to do so and will notify you promptly.",
          "5.By replying or tagging #luckybox, #luckyboxaustralia and/or @luckyboxaustralia or @ any of our affiliated social media links, or as otherwise requested in the message we sent to you, you are entering into an agreement with us and agree to be bound by this Policy, THE PRIVACY POLICY and TERMS OF USE without limitation or qualification. ",
          '6.You hereby irrevocably grant us, as well as our respective licensees, successors and assigns (herein collectively called the "Licensed Parties"), the non-exclusive, worldwide, perpetual, fully-paid, royalty-free, sub-licensable right to use, reproduce, publish, broadcast, post online, and copyright (i) Your name, username, voice, picture, portrait and likeness (“Name and/or Likeness”) as such Name and/or Likeness appears in or in connection with this Policy and (ii) the Policy in connection with advertising, marketing and promoting us, our products and services, or its sponsored events, in whole or in part, by any and all means, media, devices, processes and technology now or hereafter known or devised throughout the universe. You understand and acknowledge that we may modify the Policy for formatting, navigational, integration or other purposes, but such modifications will not materially distort such usage of you.',
          "7.No advertisement, promotional media, or other material in any media containing Your Name and/or Likeness related to the Prizes need be submitted to you for any further approval for use by the Licensed Parties, and the Licensed Parties shall be without liability to you for any distortion or illusionary effect resulting from the publication of Your Name and/or Likeness.",
          "8.Notwithstanding the above, the Licensed Parties will have no obligation to make any use of any of the rights granted by you. Our use of your Name and/or Likeness does not imply any endorsement of or any affiliation with you.",
          "9.You acknowledges and agrees to indemnify and release Lucky Box and any of its affiliates, contractors, partners  for any liability arising out of or in connection with any damage, loss, injury, disappointment, death, breach of any laws, negligent, reckless or wilful wrongdoing act or omission, IP claim as a result of claiming and use of the Prizes. ",
          "10.Lucky Box does not provide any form of authentication, warranty, support, updates or any other functional liability in perpetuity for any of the Prizes. Upon receipt of the Prizes, you accept all responsibilities and risk in relation to the Prizes.",
          "11.By accepting the Prizes (whether formally or by way of implicitly), you agree to be bound by the Policy. ",
          "12.It is the intention of the parties that every endeavour will be made to resolve disputes on their merits, reasonably, by negotiation.",
          "If a dispute arises, either party may give a written notice to the other party that adequately describes the particulars of the alleged dispute.",
          "Within 20 business days of a dispute notice being given, representatives of each party must meet and undertake negotiations in good faith to resolve the dispute or a view to resolving the dispute. Unless otherwise agreed by the parties, the meeting will be held in Sydney, NSW.",
          "If the dispute is not resolved or a method to resolve within 10 business days after the dispute meeting then the parties agree to submit the dispute to the relevant expert authorised mediation body for final and binding determination. The costs of the mediation must be shared equally irrespective of any determination made.",
          "No party may commence litigation to resolve any dispute prior to any mediation determination.",
          "13.Written notices may be sent to:",
          "Management<br>JB Equipment Service Pty Ltd<br>U53/14 Loyalty Rd <br>North Rocks<br>NSW<br>Australia 2151",
          "And will be deemed receipted upon acknowledgement from Us to you.",
          "14.We reserve the right to modify this Policy by posting new versions thereof, as well as the right to provide additional notice of material changes, which modifications and changes will be effective immediately.",
          "15.This Policy is governed by the laws of NSW, Australia.",
        ],
      },
    ],
  },
  terms: {
    title: "Terms & condition",
    list: [
      {
        q: "Terms of Service",
        a: [
          "JB Equipment Service Pty Ltd (“Lucky Box,” “we,” “us,” or “our”)  provide our online websites (“Sites”), mobile device applications (“Apps”) and other services (together with the Sites and Apps, collectively, the “Services”), as a service to our customers (“You,” “your,” “yours”). ",
          "The following terms of service (“Terms”) set forth the basic rules that govern your use of the Services owned or operated by us that display these Terms.",
          "PLEASE READ THESE TERMS OF USE CAREFULLY BEFORE USING THE SERVICES. THEY GOVERN YOUR USE OF THE SERVICES. IF YOU DO NOT AGREE TO THE TERMS STATED BELOW,  PLEASE DO NOT USE THE SERVICES. ",
          "Your continued use of this Service constitutes your knowledge, understanding, and acceptance of the Terms, and your agreement to be bound by the Terms and that such use of the Services is sufficient consideration under the Terms. Lucky Box may immediately terminate this agreement with you (including your access to the our Services) if you breach any provision of these Terms.",
          "ALL DISPUTES BETWEEN YOU AND US MUST BE RESOLVED BY INDIVIDUAL BINDING ARBITRATION. PLEASE READ THE ARBITRATION PROVISION IN THESE TERMS AS IT AFFECTS YOUR RIGHTS UNDER THIS CONTRACT.",
        ],
      },
      {
        q: "1.Site or App Contents",
        a: [
          "Access to and use of the Services is solely for your purchase of our products for personal use, information, education, entertainment, and communication with us. You may download, copy) this Site or App for your personal non-commercial use only. No right, title or interest in any of the contents of this Site or App is transferred to you as a result of any accessing, downloading, copying, printing or use of an App or this Site.",
          "All our character(s), related items, and accounts created or used in or on any of our Services are and shall remain the property of Lucky Box or its licensors and cannot be sold, licensed, assigned, transferred or loaned to third-parties.",
        ],
      },
      {
        q: "2.Intellectual Property Rights",
        a: [
          'The names, text, Trademarks, logos, images, graphics, photos, video files, characters, animation, application functionality, chat transcripts, or any other digital media, and their arrangement on this Service (“Content”) are all subject to patent, copyright, trademark and other intellectual property protection. Content may not be copied for commercial use or distribution, nor may Content be modified, processed, or reposted to other websites. The trademarks, trade names, trade dress, logos, and service marks (collectively, the "Trademarks") displayed on this Service are the registered and/or unregistered Trademarks of Lucky Box, and such third parties that may own the displayed Trademarks.  Nothing contained on this Service or in the Terms serves to grant to you, by implication or otherwise, any license or right to use any Trademarks displayed on this Service without the written permission of Lucky Box or such third party that may own the displayed Trademarks. You do not acquire any right, title or interest in any Content by accessing or using the Services. Any rights not expressly granted herein are reserved. Except as set forth below, the use of any Content available on a Service is strictly prohibited.',
          "Subject to your compliance with this Agreement, we grant you a limited license to access and use the Services and their Content for personal, informational, and shopping purposes. No Content from the Services may be copied, reproduced, republished, performed, displayed, downloaded, posted, transmitted, or distributed in any way without our written permission.  Except as otherwise expressly authorized herein or in writing by us, you agree not to reproduce, modify, rent, lease, perform, display, transmit, loan, sell, distribute, or create derivative works based (in whole or in part) on all or any part of the Services or the Content.",
        ],
      },
      {
        q: "3.User Comments and Other Submissions",
        a: [
          "We offer you the opportunity to interact with this Service, and in doing so, you are permitted to send us your comments and unsolicited creative or original concepts, ideas, materials or products, whether confidential or proprietary information, or the like (“Submission(s)”).",
          "We are under no obligation to use or compensate you for your Submissions. We will not respond to you regarding your Submissions, and your Submissions will not be returned to you and will not be treated as confidential information.",
          "Note that in your communications with us, including by email, text or through forms on our Services, we ask that you please do not send us any sensitive information pertaining to yourself. We does not knowingly or intentionally collect or store sensitive information or special categories of information.",
        ],
      },
      {
        q: "4.License to Use Submissions",
        a: [
          "Except as otherwise described in any additional terms (such as the contests or sweepstakes official rules), which shall also govern Submissions, you grant us a non-exclusive, unrestricted, unconditional, unlimited, worldwide, irrevocable, perpetual, and cost and royalty free license and right to use, copy, record, distribute, reproduce, disclose, sell, re-sell, sublicense (through multiple levels), display, publicly perform, transmit, publish, broadcast, translate, make derivative works of, and otherwise exploit in any legal manner, all or any portion of your Submission (and derivative works thereof), for any purpose in all formats, on or through any media, software, formula, or medium now known or hereafter developed, and with any technology or devices now known or hereafter developed, and to advertise, market, and promote the same.",
          "Except as limited by our Privacy Policy, the rights granted by you include, without limitation, the right to: (a) configure, host, index, cache, archive, store, digitise, compress, optimize, modify, reformat, edit, adapt, publish in searchable format, and remove such Submission or combine same with other materials, use any ideas, concepts, know-how, or techniques contained in any Submission for any purposes, including, without limitation, developing, manufacturing, and marketing products and/or services.",
          "Except as limited by our Privacy Policy, in order to further practice the rights and license that you grant to us to your Submissions, you also grant us, the unconditional, perpetual, irrevocable right to use and exploit your name, persona, and likeness in connection with any Submission.",
          " Except as prohibited by law, you waive any moral rights (including attribution and integrity) that you may have in any Submission, even if it is altered or changed in a manner not agreeable to you. To the extent non-waivable, you irrevocably agree not to exercise such rights (if any) in a manner that interferes with any exercise of the rights granted by this license.",
        ],
      },
      {
        q: "5.User Generated or Third-Party Content",
        a: [
          "Although third-party or user-generated content may be posted on this Site (“User Content”), the posting of your content does not constitute our endorsement of the User Content. You agree to clearly and conspicuously disclose if you receive compensation or incentive of any kind in exchange for your Submissions or User Content.",
          "We are not responsible or liable for any claim, including, without limitation, loss or injury to real, tangible, or intellectual property, violations of personal privacy or privacy rights, actual, consequential or punitive damages, personal injury, or wrongful death in connection with third-party or User Content.",
          "You understand that you, and not us, are entirely responsible for all User Content that you upload, post, email, transmit or otherwise make available via the Services. We does not proactively and routinely screen or monitor User Content posted to the Services by others and, as such, does not guarantee the accuracy, integrity or quality of such content.",
          "We may, in its sole discretion, screen, monitor, refuse or remove any Submission, or remove any User Content that violates the Terms or is otherwise objectionable. You understand that by using the Services, you may be exposed to User Content that is offensive, indecent or objectionable. Under no circumstances will we be liable in any way for any User Content, including, but not limited to, any errors or omissions in any User Content, or any loss or damage of any kind incurred as a result of the use of any User Content made available on the Site. You agree to bear all risks associated with the use of any User Content available in connection with the Services, including any reliance on the accuracy, completeness, or usefulness of such User Content. You agree that we are not responsible for, and does not endorse, User Content posted on the Site. If your User Content violates these Terms, you may bear legal responsibility for that User Content. As between you and us, any User Content will be non-confidential and non-proprietary and we will not be liable for any use or disclosure of such content.",
        ],
      },
      {
        q: "6.Other Services",
        a: [
          "Additional terms and conditions may apply to other Lucky Box services, such as third-party service provider agreements, or rules for sweepstakes and contests, purchases, services or other online activities (“Other Services”). ",
        ],
      },
      {
        q: "7.Third-Party Sites",
        a: [
          "Third-party links on this Service may direct you to third-party websites (“Third-Party Sites”) that are not under the control of us that may be located in different countries, and those websites and the products sold on those websites are likely to be subject to the originating country’s regulatory or product safety requirements. You understand and acknowledge that information that you share or provide to third-party websites will be subject to different privacy policies and terms of use.",
          "We are not liable for any harm or damages related to the purchase or use of goods, services, resources, content, or any other transactions (“Third-Party Products”) made in connection with any Third-Party Sites. Please review carefully the third party's policies and practices and make sure you understand them before you engage in any transaction. Complaints, claims, concerns, or questions regarding Third-Party Products should be directed to the third party.",
        ],
      },
      {
        q: "8.Prohibited Uses",
        a: [
          "In addition to other prohibitions as set forth in the Terms, you are prohibited from using the Site, App, or its Content: (a) for any unlawful purpose; (b) to solicit others to perform or participate in any unlawful acts; (c) to violate any international, national, or local regulations, rules, laws, or local ordinances; (d) to infringe upon or violate our intellectual property rights or the intellectual property rights of others; (e) to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability; (f) to submit false or misleading information; (g) to upload or transmit viruses or any other type of malicious code that will or may be used in any way that will affect the functionality or operation of our Sites or App, other websites, or the Internet; (h) to collect, track, or disclose sensitive information or personal information of others unless requested by us; (i) to spam, mailbomb, phish, or track others; (j) use any data mining, bots, spiders, automated tools or similar data gathering and extraction methods, directly or indirectly, on the Services or to collect any information from the Services or any other user of the Services; (k) for any obscene or immoral purpose; or (l) to interfere with or circumvent the security features of this Site or App, other websites, apps or the Internet, including without limitation, password protected accounts or access. We reserve the right to terminate your use of the Services for violating any of the prohibited uses.",
          "Except as specified in our Privacy Policy, we retains the right, but not the obligation, to log, modify, monitor, post, remove, review, screen, store, and track (“Compliance Investigations”) User Content, Submissions or communications sent through our services, at any time and for any reason, including to ensure that the Submissions or communication conform to these Terms. These Compliance Investigations may take place without prior notice to you. We are not responsible for, and does not endorse or guarantee, the opinions, views, advice or recommendations posted or sent by users.",
        ],
      },
      {
        q: "9.Registration, Subscriptions, Accounts and Passwords",
        a: [
          "Some Services permit or require you to create an account to participate in additional entertainment services. By registering for such services, you agree to provide accurate, complete and current information, including, without limitation, your date of birth, payment information, and contact information for notices.",
          "You agree not to impersonate or misrepresent your affiliation with any person or entity, including, without limitation, using another person’s username, password or account information, or another person’s name or likeness, or provide false details for a parent or guardian.",
          "You agree that we may take steps to verify the accuracy of information you provide, including, without limitation, the contact information for a parent or guardian. We reserves the right to restrict or prohibit inappropriate or infringing usernames.",
          "You are responsible for the personal protection and security of any password or username that you may use to access this Service. You are responsible for all direct or indirect damages and liable for all activity conducted on this Service that can be linked or traced back to your username or password. You are obligated to immediately report a lost or stolen password or username to us at hello@luckybox.au.",
        ],
      },
      {
        q: "10.Purchases",
        a: [
          'If you wish to purchase any product or service made available through the Services ("Purchase"), you may be asked to supply certain information relevant to your Purchase including, without limitation, your credit card number, the expiration date of your credit card, your billing address, and your shipping information. We may require additional information from you prior to accepting or processing your order. You represent and warrant that: (i) you have the legal right to use any credit card(s) or other payment method(s) in connection with any Purchase; and that (ii) the information you supply to us is true, correct and complete.',
          "By submitting such information, you grant us the right to use or provide the information to third parties for purposes of facilitating the completion of Purchases or providing our Services to you.",
          "We reserve the right to refuse or cancel your order at any time for certain reasons including but not limited to: product or service availability; we suspect you are purchasing products for resale without written permission from us; errors in the description or price of the product or service; error in your order or other reasons; or if fraud or an unauthorized or illegal transaction is suspected. Furthermore, we reserve the right to collect any rejection or insufficient funds fee and to process any such payment by the payment method you have submitted to us. Your charges may be payable in advance, in arrears, or as otherwise described when you initially selected to use the paid Services.",
        ],
      },
      {
        q: "11.Privacy Statement",
        a: [
          "For information on how information is collected, used, or disclosed by us in connection with your use of this Service, please consult our Privacy Policy, which is linked to in the website footer of our Service.",
        ],
      },
      {
        q: "12.Updating Information on Sites and Apps",
        a: [
          "We undertake no obligation to update, amend or clarify information on this Site or App, including without limitation, pricing information, except as required by law. No specified update or refresh date applied on this Site or App should be taken to indicate that all information on the Site or App has been modified or updated. Please remember when reviewing information on this Site or App that such information may not represent the complete information available on a subject. In addition, subsequent events or changes in circumstances may cause existing information on this Site to become inaccurate or incomplete.",
        ],
      },
      {
        q: "13.Errors and Omissions",
        a: [
          "On occasion, communications, including information on a Site or App may contain errors, including, without limitation, typographical errors, inaccuracies, or omissions related to product availability, special offers, product promotions, pricing information, product descriptions, or product shipping charges and transit times. We reservce the right to, at any time without prior notice, correct any errors, inaccuracies or omissions and to change or update information or cancel orders if any information on the Service is inaccurate (including after you have submitted your order).",
        ],
      },
      {
        q: "14.Product and Pricing Information",
        a: [
          "This Site, App, Content, product information, and any products sold through the Services into permitted jurisdictions are intended to comply with Australian laws and regulations. Some products cannot be shipped to certain jurisdictions. ",
          "We reserve the right, but is not obligated, to limit the sales of its products or services to any person, geographic region or jurisdiction. We may exercise this right on a case-by-case basis. We reserve the right to limit the quantities of any products or services that we offer. All descriptions of products or product pricing are subject to change at any time without notice at the sole discretion of Lucky Box. We reserves the right to discontinue any product at any time. Any offer for any product or service made on this Service is void where prohibited.",
          "We do not match prices with any of our online competitors or retail partners.",
        ],
      },
      {
        q: "15.Cancellations",
        a: [
          "For most Services, registered users can log in to the My Account page to cancel their order. Orders placed by Guest checkout cannot be cancelled via this method.",
          "You will receive an email confirmation that your order has been cancelled. Please note that there is a hold on the funds associated with the order that will drop off after the cancellation. Some credit card companies may hold the funds for up to 20 business days.",
        ],
      },
      {
        q: "16.Refunds and Returns",
        a: [
          "Lucky Boxes are random and blind in nature. We do not offer returns and refunds on individual purchases due to change of mind or any dissatisfaction of the Lucky Boxes.",
          "We may in our absolute discretion offer a refund or return items must be new, unassembled, and unused in their original packaging. The customer order number must be included on the return shipping label.",
        ],
      },
      {
        q: "17.Refunds, Returns and Cancellations from other Retailers and Third-Party Sites",
        a: [
          "We does not provide refunds or cancellations for products purchased from our affiliated retailers or our products displayed on Third-Party Sites. When you purchase a product from one of our affiliated retailers or on Third-Party Sites, your return, refund or cancellation is subject to the terms, conditions and refund policies of those Third Parties.",
        ],
      },
      {
        q: "18.Limited Warranty",
        a: [
          "We will replace defective parts or issue a refund for full or partial purchase price of product within 30 days (sales receipt is required to provide proof of purchase).",
          "This warranty is valid only if the product has been assembled and maintained per the instructions. Any modifications made to the original product or product structure may render the warranty invalid. This warranty does not cover abuse, accident, cosmetic issues such as fading or scratches from the normal wear, or any other cause not arising from defects in material and workmanship.",
        ],
      },
      {
        q: "19.Limitations on Liability",
        a: [
          "YOU ACKNOWLEDGE, BY YOUR USE OF THIS SITE THAT, TO THE MAXIMUM EXTENT PERMITTED BY LAW: (1) YOUR USE OF THIS SERVICE IS AT YOUR SOLE RISK; (2) YOU ASSUME FULL RESPONSIBILITY FOR ALL COSTS ASSOCIATED WITH ALL NECESSARY SERVICING OR REPAIRS OF ANY EQUIPMENT THAT YOU USE IN CONNECTION WITH YOUR USE OF THIS SERVICE; AND (3) WE WILL NOT BE LIABLE FOR ANY DAMAGES OF ANY KIND RELATED TO YOUR USE OF OR INABILITY TO USE OUR SERVICE, INCLUDING WITHOUT LIMITATION, FAILURE OF PERFORMANCE, ERROR, OMISSION, INTERRUPTION, COMPUTER VIRUS, OR NETWORK OR INTERNET FAILURE, OR INCLUDING, WITHOUT LIMITATION, DIRECT, INDIRECT, SPECIAL, COMPENSATORY OR CONSEQUENTIAL DAMAGES, LOST PROFITS AND/OR LOSS OF OR DAMAGE TO PROPERTY WHETHER THE ALLEGED LIABILITY IS BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR ANY OTHER BASIS, EVEN IF LUCKY BOX HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IN NO EVENT WILL OUR TOTAL LIABILITY FOR ANY CLAIMS RELATED TO THIS SERVICE EXCEED TEN DOLLARS.",
          "For users located in Australia, nothing in these Terms excludes, restricts or modifies any guarantee, term, condition, warranty, or any right or remedy, implied or imposed by any law which cannot lawfully be excluded or limited, including the Australian Consumer Law which contains guarantees that protect the purchasers of products and services in certain circumstances. If any guarantee, term, condition or warranty is implied into these Terms under the Australian Consumer Law or any other applicable law (“Non-Excludable Provision”) and we are able to limit your remedy for a breach of the Non-Excludable Provision, then our liability for breach of the Non-Excludable Provision is limited to one or more of the following at our option: (a) in the case of products, the replacement of the product or the supply of equivalent products, the repair of the products, the payment of the cost of replacing the products or of acquiring equivalent products, or the payment of the cost of having the products repaired; or (b) in the case of the Services, the supplying of the Services again, or the payment of the cost of having the Services supplied again.    ",
        ],
      },
      {
        q: "20.Disclaimer",
        a: [
          "TO THE MAXIMUM EXTENT PERMITTED BY LAW, THE CONTENT PROVIDED ON THIS SERVICE IS PROVIDED “AS IS” AND “WITH ALL FAULTS.” WE MAKE NO WARRANTY OR REPRESENTATION, EITHER EXPRESS OR IMPLIED, AS TO ITS USE, AVAILABILITY, CONTENT ACCURACY, APPROPRIATENESS OR PERFORMANCE OF THIS SERVICE. AS A USER, YOU ASSUME ALL RISKS AND RESPONSIBILITIES FOR ITS USE OR NON-USE, AND WE MAKES NO REPRESENTATION THAT THE CONTENT APPEARING ON, ACCESSED THROUGH, OR DOWNLOADED FROM THIS SERVICE IS COMPATIBLE WITH YOUR DEVICE OR FREE FROM ERROR OR VIRUSES. ",
        ],
      },
      {
        q: "21.Indemnification",
        a: [
          "YOU AGREE TO INDEMNIFY AND HOLD LUCKY BOX, ITS DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, AND AFFILIATES HARMLESS FROM AND AGAINST ANY AND ALL CLAIMS, DAMAGES, COSTS AND EXPENSES, INCLUDING ATTORNEY FEES, ARISING OUT OF OR RELATED TO ANY CONTENT YOU SUBMIT, POST, TRANSMIT OR OTHERWISE SEEK TO MAKE AVAILABLE THROUGH THE SITE, YOUR USE OF THIS SITE, YOUR VIOLATION OF ANY DATA PROTECTION OR PRIVACY LAWS INCLUDING CHILDREN PROTECTION LAWS, OR BREACH OF THE TERMS. YOUR RIGHTS WITH RESPECT TO LUCKY BOX ARE NOT MODIFIED BY THE FOREGOING INDEMNIFICATION IF THE LAWS OF YOUR COUNTRY OR RESIDENCE, APPLICABLE AS A RESULT OF YOUR USE OF THE SITE, DO NOT PERMIT IT.",
        ],
      },
      {
        q: "22.Contests, Sweepstakes and Promotions; Mobile Marketing",
        a: [
          "a.Contests, Sweepstakes and Promotions",
          'Any contests, sweepstakes or other promotions (collectively, "Promotions") made available through the Services may be governed by rules that are separate from these Terms. If you participate in any Promotions, please review the applicable rules as well as our Privacy Policy. If the rules for a Promotion conflict with these Terms, the Promotion rules shall apply.',
          "1.Mobile Marketing",
          'You may sign up to receive communications about our products and services through your mobile device by submitting your mobile telephone number online or by texting us. By opting into our SMS Mobile Marketing program, you authorize us to send to your mobile phone number using automated technology text messages about our products, promotions, offers, and sales. No purchase is necessary to opt-in to our SMS Mobile Marketing Program and your opting in is not a condition to your purchasing goods or services from us. You understand that standard message and data rates may apply and that your carrier may charge your account with that carrier for text messages that you receive from us or send to us. We are not responsible for these charges. It is your responsibility to notify us if at any time you change your phone number, cancel your service, or transfer your number to another party. You can unsubscribe from our text messaging program at any time by replying "STOP" to any message that you receive from us. If your carrier does not permit text messages, you may not receive the text messages. Neither we nor the carrier will be liable for any delays in the receipt of, or failure to deliver, any text messages, as delivery is subject to effective transmission from your network operator.',
        ],
      },
      {
        q: "23.Changes to the Terms",
        a: [
          "We may update, change, modify, add or remove portions of the Terms from time to time as we update or expand our Services. ",
        ],
      },
    ],
  },
  privacy: {
    title: "Privacy Policy",
    list: [
      {
        q: "Privacy Policy",
        a: [
          "We at JB Equipment Service Pty Ltd (“Lucky Box,” “we,” “us,” or “our”) understand that you care about how the information you provide us is used and shared. ",
          "We respect your privacy and is committed to protecting your personal data, the types of personal information that we collect, how we use it, how and when it may be shared, and the rights and choices you have with respect to your information (Personal Information). It also explains how we communicate with you and how you can make requests or submit inquires to us about your information. Our goal is to help you understand all of this, as part of our commitment to maintaining your trust.",
          "This Privacy Policy (the Policy) relates to the information collection and use practices by us in connection with our online services available at www.luckybox.au or any other websites, pages, features, or content we own or operate (the “Sites”), our mobile/tablet applications (“Apps”), and any other Lucky Box products and services offered through any other websites and mobile applications that direct you to this Policy (collectively, including the Sites and the Apps, the “Services”).",
        ],
      },
      {
        q: "1.Privacy Policy Acceptance",
        a: [
          "By accessing and using our Services, you are agreeing to the terms of this Policy. Where we require your consent to process your Personal Information, we will ask for your consent to the collection, use, and disclosure of your Personal Information in advance, and such consent is voluntary and can be withdrawn at any time. However, if you withdraw such consent, we may not be able to provide you Services.",
          "If you do not agree with or you are not comfortable with any part of this Policy, please immediately discontinue access or use of our Services.",
          "We may change this Policy from time to time without notice to you. ",
        ],
      },
      {
        q: "2.What Personal Information Do We Collect From You",
        a: [
          "As you use our Services, we collect certain information about you and your use of the Services including (but not limited to): ",
          "(1) information you voluntarily provide us, ",
          "(2) information we collect from you automatically; and ",
          "(3) information we obtain from third parties. ",
          "Information You Voluntarily Provide",
          "Account Registration: information relating to the creation of an account on our Sites or Apps, such as, first and last name, email address, postal address, phone number, and/or a unique individual password. By submitting a telephone number to us, you agree that a representative of Lucky Box can contact you at the number you provide, potentially using automated technology (including texts/SMS messaging) or a pre-recorded message. Your consent is not an obligation to receive any of our products.",
          "Product Registration: information relating to registering your purchased products with us, such as, first and last name, email address, postal address, phone number, product name, product or model number, purchase date and place, date of birth, and gender.",
          "Customer Service: records and copies of your correspondence (including email addresses), if you contact us via email or via our online forms, or when you contact us by telephone.",
          "Payment Information: we may collect your payment card, transaction, or financial account information, when you purchase our products. All credit card information is held by our payment gateway provider employing industry standard protections. We may also collect other Personal Information at the request of the business with whom you are transacting.",
          "Information Collected Via Authorised Access: if you provide your permission, we may collect and store any Personal Information you provide while using our Services (e.g., by authorizing us to access to your device's camera, photo album, and microphone). If you have previously provided authorisation and no longer wish to allow access, you may edit the application settings at the device level.",
          "Information Collected Automatically",
          "Information About Your Use of the Services: when you browse our Sites, we automatically collect information such as your web request, Internet Protocol (“IP”) address, browser type, domain names, referring and exit pages and URLs, pages viewed and the order of these page views, the date and time you access our servers.",
          "Device Information: if you access the Services through a mobile device (e.g., a smart phone or tablet), we may be able to identify your devices’ unique device identifier, international mobile equipment identity (“IMEI”), mobile equipment identifier (“MEID”), MAC address, devices’ operating system, devices’ physical location (including geolocation, beacon-based location, and GPS location) of your device, your mobile network information, and your mobile device’s advertising ID. You may disable our use of certain location data through your device or browser settings.",
          "Information Collected From Other Sources",
          "From time to time, we may obtain information about you from third party sources as required or permitted by law. For instances, we may receive information about you from other sources to help us correct or supplement our records, improve the quality or personalization of our Services to you, and prevent or detect fraud. We may also collect Personal Information about you that we receive from our partners, group members or other third parties. For example, if you visit one of our social media accounts, the respective provider may provide us with statistical information regarding your use of our account. By gathering additional information about you, we can correct inaccurate information, and enhance the security of your transactions.",
        ],
      },
      {
        q: "3.Why Do We Collect Your Information?",
        a: [
          "We use your Personal Information as described in this Policy for business and commercial purposes, or as disclosed to you prior to processing your information. We may process your Personal Information for the following purposes:",
          "(1) To provide you any services or products that you request, redeem or purchase, for example, to process your payments and communicate with you regarding your purchase or redemption. If you purchase products from this Website, your personal data will be collected and used by us for the purposes of fulfilling your order and delivering products to you. ",
          "(2) Respond to Requests. To provide customer support, respond to your inquiries and fulfill your requests.",
          "(3) Provide Services Communication. To send administrative information to you, for example, information regarding our Services and changes to our terms, conditions, and policies.",
          "(4) Provide Marketing Communications. To send you marketing communications that we believe may be of interest to you, such as to contact you via mail, email, or telephone in order to give you updates about our special promotions, new services.",
          "(5) Provide Sweepstake or Contest. To allow you to participate in sweepstakes, competitions, contests, and similar promotions and to administer these activities. Some of these activities have additional rules, which could contain additional information about how we use and disclose your Personal Information.",
          "(6) Research and Development of Our Services. For our business purposes, such as data analysis, audits, fraud monitoring and prevention, developing new products and services, enhancing, improving, or modifying our products and services, identifying usage trends, determining the effectiveness of our promotional campaigns, and operating and expanding our business activities.",
          "(7) Personalise Your Experience. We may also process our information to personalise your experience. Please see Section 7, Cookies and Other Similar Tracking Technologies below for more information. By personalising, we enable you to interact with our Services more easily across platforms and devices and allow us to tailor our Services specific to your needs.",
          "(8) Detect and Prevent Fraud. We may process your Personal Information, such as your IP address, to help monitor, prevent, and detect fraud and abusive use of our Services, enhance security, and combat other security risks.",
          "(9) Facilitate Corporate Acquisitions, Mergers and Transactions. We may process any information regarding your account and use of our Services as is necessary in the context of corporate acquisitions, mergers, or other corporate transactions.",
          "(10) With Your Consent. For any other purposes disclosed to you prior to you providing us your Personal Information or which is reasonably necessary to provide the Services or other related products and/or services requested, with your permission or upon your direction.",
        ],
      },
      {
        q: "4.How Do We Share Your Personal Information?",
        a: [
          "We may share your Personal Information to fulfill the purposes described in this Privacy Policy and as permitted by applicable law. We may share your Personal Information in the following ways:",
          "(1) To our subsidiary and affiliated companies",
          "(2) To our strategic partners and service providers who provide services such as website hosting, data analysis, payment processing services, order fulfillment, information technology and related infrastructure provision, customer service, email delivery, credit card processing, auditing, and other similar services.",
          "(3) To third-party partners to permit them to send you marketing communications, consistent with your choices. To third-party sponsors of sweepstakes, contests, and similar promotions.",
          "(4) We may disclose your information if we believe that the disclosure is required by law, if we believe that the disclosure is necessary to enforce our agreements or policies, in response to valid requests by public authorities (e.g., a court or a government agency), or if we believe that the disclosure will help us protect the rights, property, or safety of Lucky Box or our customers.",
          "(5) We may disclose Personal Information to a buyer or other successor in the event of a merger, divestiture, restructuring, reorganisation, dissolution or sale or transfer of some or all of our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Information held by us is among the assets transferred. By engaging with us or using our Services, you understand and agree to our assignment or transfer of rights to your Personal Information.",
          "(6) We may share or disclose your Personal Information with any of the our outside professionals to facilitate the professional advice from those outside professionals.",
          "(7) We do not share your Personal Information with outside, non-affiliated third parties without your consent, except to the extent necessary to complete your request for our Services.",
        ],
      },
      {
        q: "5.How Does Lucky Box Handle Children’s Information?",
        a: [
          "When our Site or App collects age, and there is an age in your jurisdiction under which parental consent or authorisation is required to use the Service, we will either block users under that age or will ask them to provide consent or authorisation from a parent or guardian before they can use it. We will not knowingly ask children under that age to provide more data than is required to provide for the product.",
        ],
      },
      {
        q: "6.Information About Cookies and Other Similar Tracking Technologies",
        a: [
          "We and third parties on our behalf may employ various tracking technologies, such as cookies, web beacons, analytics software, and other similar technologies (collectively, “Tracking Technologies”) to collect additional Personal Information automatically as you interact with the Services. We use these Tracking Technologies because they help us to personalize your experience with our Services, and help us better manage content on our Services by informing us what content is effective.",
          "Cookies. When you visit our Sites or otherwise interact with the Services, we (or third-party data or ad networks that we work) may send one or more “cookies” to your computer or other devices. Cookies are alphanumeric identifiers stored on your computer through your web browser and are used by most websites to help personalize your web experience. Some cookies may facilitate additional site features for enhanced performance and functionality such as remembering preferences, allowing social interactions, analyzing usage for site optimization, providing custom content, allowing third parties to provide social sharing tools, and serving images or videos from third-party websites. Some features on the Sites will not function if you do not allow cookies. We may link the information we store in cookies to any Personal Information you submit while on our Sites. ",
          "Web Beacons and Local Shared Objects. We (or third-party data or ad networks we work with) may use web beacons alone or in conjunction with cookies to compile information about our Services, or other information we or they have collected. Web beacons are tiny graphic objects that are embedded in a web page or email and are usually invisible to the user but allow checking that a user has viewed the page or email. Web beacons may be used within the Services to track web page visits or form submissions. In some cases, we tie the information gathered by web beacons to Personal Information.",
          "We also use Local Shared Objects, such as Flash cookies, AND/OR Local Storage, such as HTML5, to store content information and preferences. Various browsers may offer their own management tools for removing HTML5. ",
          "Analytics Software. We use Google Analytics and third party SEO, which are third-party services that uses cookies and other, similar technologies to collect and analyse information about use of the Services and report on activities and trends. This service may also collect information regarding the use of other websites, apps, and online resources. Links to Other Third Party Websites",
          "Our Services may contain links to third-party websites. We have no control over the privacy practices or the content of these websites or any third-party offering, site or other products and services used in connection with our Services. The inclusion of a link does not imply endorsement of the linked site or service by us or by our affiliates. The third parties you interact with may have their own privacy policies, and MGA is not responsible for their operations or their use of information they collect. We encourage you to check the applicable third-party privacy policy and terms of use when visiting or using any third-party services.",
        ],
      },
      {
        q: "7.How Does Lucky Box Protect Your Information?",
        a: [
          "We reasonably maintain our Services and all associated data with technical, administrative, and physical safeguards to protect against loss, unauthorised access, destruction, misuse, modification, and improper disclosure. Unfortunately, no data transmission or storage system can be guaranteed to be 100% secure. We cannot guarantee the security of our databases or the databases of the third parties with which we may share such information, nor can we guarantee that the information you supply will not be intercepted while being transmitted over the Internet. In particular, e-mail sent to us may not be secure, and you should therefore take special care in deciding what information you send to us via e-mail (and please do not send us sensitive information, such as payment card information or government issued IDs).",
          " You are responsible for protecting your password(s) and maintaining the security of your browsers and devices. If you have any questions about the security of your interaction with us (if, for example, you feel that the security of any account you might have with us has been compromised), you can contact us at hello@luckybox.au.",
          "We will retain your Personal Information for as long as needed to provide the applicable Services you use, unless a longer retention period is required or permitted by law. We will retain and use information as necessary to comply with our legal obligations, resolve disputes, and enforce our rights. We will cease to retain your Personal Information or remove the means by which the data can be associated with you as soon as it is reasonable to assume that such retention no longer serves the purpose for which the Personal Information was collected and is no longer necessary for legal or business purposes.",
        ],
      },
      {
        q: "8.Changes to This Privacy Policy",
        a: [
          "We reserves the right to change this Privacy Policy at any time. Your use of the Services following these changes means that you accept the revised Privacy Policy.",
        ],
      },
      {
        q: "9. No Reservations",
        a: [
          "We do not accept any reservation or any type of limited acceptance of our privacy policy. You expressly agree to each and every term and condition as stipulated in this Policy without any exception whatsoever.",
        ],
      },
    ],
  },
};
export default helpdata;
