<template>
  <div class="section-bottom">
    <div class="content-box">
      <div class="icon-lucky"></div>
      <ul class="info-list">
        <li class="info-kong"></li>
        <li class="info-item">
          <p class="title">Navigation</p>
          <router-link class="name" to="/">Home</router-link>
          <router-link class="name" to="/box">What's in the Box</router-link>
          <router-link class="name" to="/membership">Membership</router-link>
          <router-link class="name" to="/store">Store Locator</router-link>
          <router-link class="name" to="/about">About us</router-link>
          <router-link class="name" to="/news">News</router-link>
        </li>
        <li class="info-item">
          <p class="title">Help</p>
          <router-link
            class="name"
            :to="{ path: '/help', query: { type: 'faq' } }"
            >FAQ’s</router-link
          >
          <router-link
            class="name"
            :to="{ path: '/help', query: { type: 'prize' } }"
            >Prize Redeem Policy</router-link
          >
          <router-link
            class="name"
            :to="{ path: '/help', query: { type: 'privacy' } }"
            >Privacy Policy</router-link
          >
          <router-link
            class="name"
            :to="{ path: '/help', query: { type: 'terms' } }"
            >Terms & condition</router-link
          >
        </li>
        <li class="info-item">
          <p class="title">Contact Us</p>
          <p class="name">
            <a href="mailto:hello@luckybox.au">hello@luckybox.au</a>
          </p>
        </li>
      </ul>
      <div class="find-list">
        <div class="title">Find Us On</div>
        <div class="icon-list">
          <!-- <img src="../assets/img/bottom/icon-1.png" alt="" /> -->
          <img @click="goOthers" src="../assets/img/bottom/icon-2.png" alt="" />
          <!-- <img src="../assets/img/bottom/icon-3.png" alt="" />
          <img src="../assets/img/bottom/icon-4.png" alt="" />
          <img src="../assets/img/bottom/icon-5.png" alt="" />
          <img src="../assets/img/bottom/icon-6.png" alt="" /> -->
        </div>
      </div>
      <div class="tips-box">
        <i class="line"></i>
        <p class="text">
          Copyright © 2022 Lucky Box Australia all rights reserved
        </p>
      </div>
    </div>
  </div>
</template>

<script>
// import { getAddress } from "@/api/address";
// import { isAddressTimeOver } from "@/util/index";

export default {
  name: "PageBottom",
  props: {
    msg: String,
  },
  data() {
    return {
      activeNames: [],
    };
  },
  mounted() {
    // if (!window.localStorage.getItem("addressDate") || isAddressTimeOver()) {
    //   this.getAllAddress();
    // }
  },
  methods: {
    goOthers() {
      window.location.href =
        "https://instagram.com/luckyboxaustralia?igshid=YmMyMTA2M2Y=";
    },
    // async getAllAddress() {
    //   let options = [];
    //   const res = await getAddress({}, "state");
    //   options = res.info;
    //   for (let i = 0; i < options.length; i++) {
    //     const r = await getAddress({}, options[i].name);
    //     options[i].children = r.info;
    //   }
    //   window.localStorage.setItem("addressDate", JSON.stringify(options));
    //   const t = new Date().getTime() / 1000 / 60 / 60;
    //   window.localStorage.setItem("AddressTime", t);
    // },
  },
};
</script>

<style lang="scss" scoped>
.section-bottom {
  width: 100%;
  background: #231815;
  .content-box {
    width: 1190px;
    margin: 0 auto;
    padding: 80px 0 50px;
    position: relative;
  }
  .icon-lucky {
    width: 205px;
    height: 54px;
    background: url("../assets/img/bottom/icon-logo.jpg") no-repeat center;
    background-size: 100% 100%;
    position: absolute;
    top: 80px;
    left: 0;
  }
  .info-list {
    display: flex;
    .info-kong {
      flex: 1;
    }
    .info-item {
      flex: 0 0 225px;
      .title {
        height: 22px;
        font-size: 16px;
        color: #ffffff;
        line-height: 22px;
        font-weight: bold;
        font-family: AlibabaPuHuiTiH;
      }
      .name {
        height: 20px;
        font-size: 14px;
        color: #ffffff;
        line-height: 20px;
        margin-top: 8px;
        font-family: AlibabaPuHuiTiR;
        display: block;
        cursor: pointer;
        &:hover {
          color: #f4cc25;
        }
        a:link {
          color: #ffffff;
        }
        a:hover {
          color: #f4cc25;
        }
      }
    }
  }
  .find-list {
    position: absolute;
    top: 170px;
    left: 966px;
    .title {
      height: 22px;
      font-size: 16px;
      color: #ffffff;
      line-height: 22px;
      font-weight: bold;
      font-family: AlibabaPuHuiTiH;
    }
    .icon-list {
      margin-top: 8px;
      img {
        width: 18px;
        height: 18px;
        margin-right: 20px;
        cursor: pointer;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  .tips-box {
    width: 100%;
    text-align: center;
    margin-top: 50px;
    .line {
      width: 1440px;
      height: 1px;
      background: #3f3f3f;
      display: block;
      margin-left: -125px;
    }
    .text {
      height: 20px;
      font-size: 14px;
      color: #757575;
      line-height: 20px;
      margin-top: 20px;
    }
  }
}
</style>
