<template>
  <el-dialog
    class="lk-dialog"
    title=""
    :visible.sync="dialogVisible"
    width="600"
  >
    <h1 class="title">{{ title }}</h1>
    <p class="text">{{ text }}</p>
    <LkBt @submit="submit" class="bt" :msg="'OK'"></LkBt>
  </el-dialog>
</template>

<script>
import LkBt from "./LkBt.vue";
export default {
  name: "LkAlert",
  components: {
    LkBt,
  },
  data() {
    return {
      dialogVisible: false,
      title: "Thanks for your share!",
      text: "Your information has been submitted successfully.",
      bt: "OK",
    };
  },
  methods: {
    show({ title, text, bt }) {
      this.title = title || this.title;
      this.text = text || this.text;
      this.bt = bt || this.bt;
      this.dialogVisible = true;
    },
    close() {
      this.dialogVisible = false;
    },
    submit() {
      console.log(123123);
      this.close();
    },
    cancel() {
      this.close();
    },
  },
};
</script>
<style lang="scss" scoped>
.lk-dialog::v-deep {
  box-sizing: border-box;
  text-align: center;
  .el-dialog {
    width: 640px;
  }
  .el-dialog__body {
    padding: 40px 30px;
  }
  .el-dialog__header {
    padding: 0;
  }
  .title {
    font-size: 26px;
    font-family: AlibabaPuHuiTiH;
    color: #000000;
    line-height: 35px;
  }
  .text {
    font-size: 20px;
    font-family: AlibabaPuHuiTiM;
    color: #000000;
    line-height: 27px;
    margin-top: 20px;
  }
  .el-dialog__headerbtn .el-dialog__close {
    width: 16px;
    height: 16px;
    background: url("../assets/img/membership/icon-x.png") no-repeat center;
    background-size: 100%;
  }
  .el-icon-close:before {
    display: none;
  }
  .el-dialog__headerbtn {
    top: 30px;
    right: 30px;
  }
}
.bt::v-deep {
  width: 200px;
  margin-top: 40px;
  .ui-lk-bt {
    width: 100%;
    height: 60px;
    line-height: 55px;
    background: #f4cc25;
    font-size: 20px;
    font-family: AlibabaPuHuiTiM;
    color: #000;
    z-index: 10;
    position: relative;
    border-radius: 8px;
    border: 2px solid #000000;
    box-sizing: border-box;
  }
  .ui-lk-bt-i {
    width: 100%;
    height: 60px;
    box-sizing: border-box;
    background: #fff;
    border-radius: 8px;
    border: 2px solid #231815;
    position: absolute;
    z-index: 9;
    top: 9px;
    left: 0;
  }
  &.ui-lk-bt-box:active .ui-lk-bt,
  .ui-lk-bt:active {
    top: 4px;
  }
}
</style>
