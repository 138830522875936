<template>
  <div class="page-reset">
    <!-- banner -->
    <div class="section-banner section-box"></div>
    <!-- form -->
    <div class="section-form section-box">
      <div class="content-box">
        <el-form
          class="form-box"
          :label-position="labelPosition"
          label-width="80px"
          :model="form"
          :rules="RULES"
          ref="form"
          @submit.native.prevent
        >
          <el-form-item label="New password" prop="password">
            <el-input v-model="form.password" type="password"></el-input>
          </el-form-item>
          <el-form-item label="Comfrim password" prop="repassword">
            <el-input v-model="form.repassword" type="password"></el-input>
          </el-form-item>
          <LkBt @submit="onSubmit" class="sb-bt" :msg="'RESET PASSWORD'"></LkBt>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import LkBt from "../components/LkBt.vue";
import { resetPwd, getInfo } from "@/api/user";
import { goPageByName } from "@/util/link";
import store from "@/store";
import RULES from "@/util/rules";

export default {
  name: "ResetView",
  data() {
    return {
      labelPosition: "top",
      form: {
        password: "",
        repassword: "",
      },
      RULES,
      resetToken: "",
    };
  },
  components: {
    LkBt,
  },
  mounted() {
    console.log(this.$route.query);
    const { resetToken } = this.$route.query;
    this.resetToken = resetToken;
    let validateRepassword = (rule, value, callback) => {
      console.log(rule);
      if (value === "") {
        callback(new Error("password does not match, please retry"));
      } else if (value !== this.form.password) {
        callback(new Error("password does not match, please retry"));
      } else {
        callback();
      }
    };
    this.RULES.repassword[0].validator = validateRepassword;
  },
  methods: {
    handleChange() {},
    onSubmit() {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          if (this.form.password !== this.form.repassword) {
            this.$message("Please enter the same password");
            return false;
          }
          this.resetPwd();
        } else {
          return false;
        }
      });
    },
    // c重置密码
    async resetPwd() {
      const res = await resetPwd({
        resetToken: this.resetToken,
        newPassword: this.form.password,
      });
      if (res.code === 0) {
        this.getInfo();
        window.localStorage.setItem("authToken", res.data.authToken);
        window.localStorage.setItem("cardNo", res.data.cardNo);
        window.localStorage.setItem("email", res.data.email);
        window.localStorage.setItem("userId", res.data.userId);
        store.commit("setLoginState", true);
        this.goPageByName("membership");
      } else {
        this.$message(res.msg);
      }
    },
    // 获取个人信息
    async getInfo() {
      const res = await getInfo();
      console.log(res);
      if (res.code === 0) {
        const name = {};
        name.firstName = res.data.firstName;
        name.lastName = res.data.lastName;
        window.localStorage.setItem("lastName", res.data.lastName);
        window.localStorage.setItem("firstName", res.data.firstName);
        store.commit("setNameState", name);
      }
    },
    goPageByName(name, type) {
      goPageByName({
        name,
        query: {
          type,
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.page-reset {
  width: 100%;
  .section-banner {
    width: 100%;
    height: 360px;
    position: relative;
    background: url("../assets/img/reset/banner.jpg") no-repeat center;
    background-size: 1920px 360px;
  }
  .section-form {
    background: #f4f5f6;
    .content-box {
      width: 740px;
      margin: 0 auto;
      padding: 45px 0 120px;
    }
    .form-box::v-deep {
      text-align: left;
      &.el-form--label-top .el-form-item__label {
        font-size: 20px;
        font-family: AlibabaPuHuiTiR;
        color: #000000;
        line-height: 27px;
      }
      .input-part {
        width: 350px;
        display: inline-block;
      }
      .el-input__inner {
        height: 60px;
        border-radius: 8px;
        border: 1px solid #979797;
        background: #f4f5f6;
        font-size: 20px;
      }
      .el-form-item {
        margin-top: 45px;
        margin-bottom: 0;
      }
      .el-col-11 {
        width: 100%;
      }
      .is-error {
        .el-input__inner {
          border: 1px solid #ff0000;
        }
      }
      .el-form-item__error {
        font-size: 18px;
        font-family: AlibabaPuHuiTiR;
        color: #ff0000;
        line-height: 26px;
        // margin-top: 10px;
        padding: 0;
      }
      .time {
        position: relative;
        .el-input__suffix {
          width: 20px;
          height: 9px;
          background: url("../assets/img/create/icon-san.png") no-repeat center;
          background-size: 100%;
          position: absolute;
          top: 50%;
          right: 20px;
          transform: translate3d(0, -50%, 0);
        }
      }
    }
    .sb-bt::v-deep {
      width: 740px;
      margin-top: 50px;
      .ui-lk-bt {
        width: 100%;
        height: 60px;
        line-height: 55px;
        background: #f4cc25;
        font-size: 20px;
        font-family: AlibabaPuHuiTiM;
        color: #000;
        z-index: 10;
        position: relative;
        border-radius: 8px;
        border: 2px solid #000000;
        box-sizing: border-box;
      }
      .ui-lk-bt-i {
        width: 100%;
        height: 60px;
        box-sizing: border-box;
        background: #fff;
        border-radius: 8px;
        border: 2px solid #231815;
        position: absolute;
        z-index: 9;
        top: 9px;
        left: 0;
      }
      &.ui-lk-bt-box:active .ui-lk-bt,
      .ui-lk-bt:active {
        top: 4px;
      }
    }
  }
}
</style>
