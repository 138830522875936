import { request } from "@/common/request";
import url from "@/common/config/url";

// 兑换码获取奖品信息
export function getPrizeInfo(params) {
  return request({
    url: url.getPrizeInfo,
    method: "get",
    params,
  });
}

// 提交兑奖
export function submitRedeem(data) {
  return request({
    url: url.submitRedeem,
    method: "post",
    data,
  });
}

// 获取兑奖列表
export function getRedeemList(params) {
  return request({
    url: url.getRedeemList,
    method: "get",
    params,
  });
}
