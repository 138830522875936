<template>
  <div class="page-create">
    <!-- banner -->
    <div class="section-banner section-box"></div>
    <!-- form -->
    <div class="section-form section-box">
      <div class="content-box">
        <el-form
          class="form-box"
          :label-position="labelPosition"
          label-width="80px"
          :model="form"
          :rules="RULES"
          ref="form"
          @submit.native.prevent
        >
          <el-form-item
            label="First Name"
            class="input-part ui-m-r-40"
            prop="firstName"
          >
            <el-input v-model="form.firstName"></el-input>
          </el-form-item>
          <el-form-item label="Last Name" class="input-part" prop="lastName">
            <el-input v-model="form.lastName"></el-input>
          </el-form-item>
          <el-form-item label="Email" prop="email">
            <el-input v-model="form.email"></el-input>
          </el-form-item>
          <el-form-item label="Birthday (optional)">
            <el-col :span="11">
              <el-date-picker
                type="date"
                placeholder="Birthday"
                v-model="form.birthday"
                style="width: 100%"
                @change="onDateConfirm"
                class="time"
                :clearable="clearable"
              ></el-date-picker>
            </el-col>
          </el-form-item>
          <el-form-item label="Password" prop="password">
            <el-input v-model="form.password" type="password"></el-input>
          </el-form-item>
          <el-form-item label="Comfrim password" prop="repassword">
            <el-input v-model="form.repassword" type="password"></el-input>
          </el-form-item>
          <p class="text-policy">
            <i @click="onAgree" :class="{ active: isAgreePolicy }"></i>I agree
            to platform’s
            <span @click="goPageByName('help', 'terms')"
              >Terms & Condition</span
            >
            and
            <span @click="goPageByName('help', 'privacy')">Privacy Policy</span>
          </p>
          <LkBt
            @submit="onSubmit"
            class="sb-bt"
            :msg="'CREATE MY ACCOUNT'"
          ></LkBt>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import LkBt from "../components/LkBt.vue";
import { register, getInfo } from "@/api/user";
import { goMembership, goPageByName } from "@/util/link";
import store from "@/store";
import RULES from "@/util/rules";

export default {
  name: "CreateView",
  data() {
    return {
      labelPosition: "top",
      minDate: new Date(1980, 0, 1),
      maxDate: new Date(),
      currentDate: new Date(2000, 0, 1),
      isChooseDate: false,
      dateshow: false,
      form: {
        firstName: "",
        lastName: "",
        password: "",
        repassword: "",
        email: "",
        birthday: "2000-01-01",
      },
      isAgreePolicy: false,
      clearable: false,
      RULES,
    };
  },
  components: {
    LkBt,
  },
  mounted() {
    console.log(this.$route.query);
    let validateRepassword = (rule, value, callback) => {
      console.log(rule);
      if (value === "") {
        callback(new Error("password does not match, please retry"));
      } else if (value !== this.form.password) {
        callback(new Error("password does not match, please retry"));
      } else {
        callback();
      }
    };
    this.RULES.repassword[0].validator = validateRepassword;
  },
  methods: {
    handleChange() {},
    onSubmit() {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          if (this.form.password !== this.form.repassword) {
            this.$message("Please enter the same password");
            return false;
          }
          if (!this.isAgreePolicy) {
            this.$message("Please select I Agree to register");
            this.isAgreePolicy = true;
            return false;
          }
          this.register();
        } else {
          return false;
        }
      });
    },
    onDateConfirm(value) {
      const y = value.getFullYear();
      const m = value.getMonth() + 1;
      const d = value.getDate();
      const birthday = `${y}-${this.changeDate(m)}-${this.changeDate(d)}`;
      this.form.birthday = birthday;
      this.isChooseDate = true;
      this.dateshow = false;
    },
    // 注册
    async register() {
      const res = await register({
        birthday: this.form.birthday,
        email: this.form.email,
        firstName: this.form.firstName,
        lastName: this.form.lastName,
        password: this.form.password,
      });
      if (res.code === 0) {
        this.getInfo();
        window.localStorage.setItem("authToken", res.data.authToken);
        window.localStorage.setItem("cardNo", res.data.cardNo);
        window.localStorage.setItem("email", res.data.email);
        window.localStorage.setItem("userId", res.data.userId);
        store.commit("setLoginState", true);
        goMembership({
          type: "success",
        });
      } else {
        this.$message(res.msg);
      }
    },
    // 日期格式
    changeDate(value) {
      if (value <= 9) {
        return "0" + value;
      }
      return value;
    },
    // 获取个人信息
    async getInfo() {
      const res = await getInfo();
      console.log(res);
      if (res.code === 0) {
        const name = {};
        name.firstName = res.data.firstName;
        name.lastName = res.data.lastName;
        window.localStorage.setItem("lastName", res.data.lastName);
        window.localStorage.setItem("firstName", res.data.firstName);
        store.commit("setNameState", name);
      }
    },
    onAgree() {
      this.isAgreePolicy = !this.isAgreePolicy;
    },
    goPageByName(name, type) {
      goPageByName({
        name,
        query: {
          type,
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.page-create {
  width: 100%;
  .section-banner {
    width: 100%;
    height: 360px;
    position: relative;
    background: url("../assets/img/create/banner.jpg") no-repeat center;
    background-size: 1920px 360px;
  }
  .section-form {
    background: #f4f5f6;
    .content-box {
      width: 740px;
      margin: 0 auto;
      padding: 45px 0 120px;
    }
    .form-box::v-deep {
      text-align: left;
      &.el-form--label-top .el-form-item__label {
        font-size: 20px;
        font-family: AlibabaPuHuiTiR;
        color: #000000;
        line-height: 27px;
      }
      .input-part {
        width: 350px;
        display: inline-block;
      }
      .el-input__inner {
        height: 60px;
        border-radius: 8px;
        border: 1px solid #979797;
        background: #f4f5f6;
        font-size: 20px;
      }
      .el-form-item {
        margin-top: 45px;
        margin-bottom: 0;
      }
      .el-col-11 {
        width: 100%;
      }
      .is-error {
        .el-input__inner {
          border: 1px solid #ff0000;
        }
      }
      .el-form-item__error {
        font-size: 18px;
        font-family: AlibabaPuHuiTiR;
        color: #ff0000;
        line-height: 26px;
        // margin-top: 10px;
        padding: 0;
      }
      .time {
        position: relative;
        .el-input__suffix {
          width: 20px;
          height: 9px;
          background: url("../assets/img/create/icon-san.png") no-repeat center;
          background-size: 100%;
          position: absolute;
          top: 50%;
          right: 20px;
          transform: translate3d(0, -50%, 0);
        }
      }
    }
    .sb-bt::v-deep {
      width: 740px;
      margin-top: 20px;
      .ui-lk-bt {
        width: 100%;
        height: 60px;
        line-height: 55px;
        background: #f4cc25;
        font-size: 20px;
        font-family: AlibabaPuHuiTiM;
        color: #000;
        z-index: 10;
        position: relative;
        border-radius: 8px;
        border: 2px solid #000000;
        box-sizing: border-box;
      }
      .ui-lk-bt-i {
        width: 100%;
        height: 60px;
        box-sizing: border-box;
        background: #fff;
        border-radius: 8px;
        border: 2px solid #231815;
        position: absolute;
        z-index: 9;
        top: 9px;
        left: 0;
      }
      &.ui-lk-bt-box:active .ui-lk-bt,
      .ui-lk-bt:active {
        top: 4px;
      }
    }
    .text-policy {
      height: 26px;
      font-size: 16px;
      font-family: AlibabaPuHuiTiR;
      color: #000000;
      line-height: 26px;
      display: flex;
      align-items: center;
      margin-top: 40px;
      i {
        width: 18px;
        height: 18px;
        background: url("../assets/img/create/icon-k.png") no-repeat center;
        background-size: 100%;
        margin-right: 5px;
        cursor: pointer;
        &.active {
          background: url("../assets/img/create/icon-x.png") no-repeat center;
          background-size: 100%;
        }
      }
      span {
        color: #000;
        font-weight: bold;
        margin: 0 5px;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}
</style>
