<template>
  <div class="section-head">
    <div class="content-box" :class="{ big: !isHeadFixed }">
      <div class="all">
        <div class="logo" @click="goPageByName('home')"></div>
        <nav>
          <router-link
            :class="{ active: item.isActive }"
            v-for="item in navData"
            :key="item.name"
            class="link"
            :to="item.path"
            >{{ item.name }}</router-link
          >
        </nav>
        <div class="right-box">
          <div class="redeem" @click="onRedeem">
            <img src="../assets/img/icon-gift.png" alt="" />
            <p>Redeem</p>
          </div>
          <div class="login" @click="onLogin">
            <img src="../assets/img/icon-user.png" alt="" />
            <p v-if="isLogin">{{ name.lastName }} {{ name.firstName }}</p>
            <p v-else>Log in</p>
          </div>
        </div>
      </div>
    </div>
    <div class="kong" :class="{ h: isHeadFixed }"></div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import store from "@/store";
import { goPageByName } from "@/util/link";

export default {
  name: "PageHead",
  data() {
    return {
      isFix: false,
    };
  },
  computed: {
    ...mapState(["navData", "isLogin", "name", "isHeadFixed"]),
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll);
    console.log(this.$route);
  },
  methods: {
    onScroll() {
      // console.log('this.$router',this.$router.history.current.name);
      if (
        this.$router.history.current.name == "home" ||
        this.$router.history.current.name == "box"
      ) {
        let scop = document.documentElement.scrollTop;
        store.commit("setHeadFixed", scop > 62);
      }
      // console.log(scop);
    },
    onLogin() {
      if (this.isLogin) {
        this.$router.push({ name: "membership" });
      } else {
        this.$router.push({ name: "logon" });
      }
    },
    onRedeem() {
      this.$router.push({ name: "redeemprizes" });
    },
    goPageByName(name) {
      goPageByName({
        name,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.section-head {
  // height: 92px;
  background: #000;
  // padding: 25px 0 24px;
  // box-sizing: border-box;
  .kong {
    height: 154px;
    &.h {
      height: 92px;
    }
  }
  .content-box {
    width: 100%;
    position: fixed;
    top: 0;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    z-index: 999;
    background: #000;
    .all {
      width: 1190px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      position: relative;
      height: 92px;
      padding: 25px 0 24px;
      box-sizing: border-box;
      transition: all 0.2s linear;
      transform: translate3d(0, 0, 0);
    }
    &.big .all {
      height: 154px;
      background: #000;
      padding: 87px 0 24px;
      .logo {
        position: absolute;
        top: 20px;
        left: 50%;
        transform: translate3d(-50%, 0, 0);
        cursor: pointer;
      }
      .right-box {
        position: absolute;
        top: 25px;
        right: 0;
      }
      nav .link {
        margin-left: 66px;
        &:first-child {
          margin-left: 0;
        }
      }
    }
    .logo {
      width: 158px;
      height: 43px;
      background: url("../assets/img/icon-luckybox.jpg") no-repeat center;
      background-size: 100%;
      left: 0;
      transition: all 0.3s linear;
      cursor: pointer;
    }
    nav {
      flex: 1;
      // transition:all 0.3s linear;
      .link {
        line-height: 42px;
        font-size: 16px;
        color: #666666;
        margin-left: 32px;
        &.active,
        &:hover {
          color: #f4cc25;
        }
      }
    }
    .right-box {
      // transition:all 0.3s linear;
      height: 42px;
      display: flex;
      align-items: center;
      .redeem,
      .login {
        display: flex;
        cursor: pointer;
        img {
          width: 26px;
          height: 26px;
          margin-right: 10px;
        }
        p {
          line-height: 30px;
          font-size: 16px;
          color: #fff;
          width: 70px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .redeem {
        margin-right: 24px;
        p {
          color: #ff521f;
        }
      }
    }
  }
}
</style>
