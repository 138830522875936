<template>
  <div class="page-redeem-history">
    <!-- banner -->
    <div class="section-banner section-box"></div>
    <!-- list -->
    <div class="section-list section-box">
      <div class="content-box">
        <template v-if="list.length">
          <div v-for="(item, index) in list" :key="index" class="list-item">
            <div class="header">
              <span class="order-num">Redeem code： {{ item.redeemCode }}</span>
              <span v-if="item.status == 2" class="status">Delivered</span>
              <span v-if="item.status == 0" class="status">In Progress</span>
              <span v-if="item.status == 1" class="status"
                >Attention<br />Required</span
              >
            </div>
            <p class="order-info">Product：{{ item.prizeName }}</p>
            <div class="img-box">
              <img :src="item.image" alt="" />
            </div>
            <p class="order-info">Price: {{ item.price }}</p>
            <p class="order-info">Phone number: {{ item.phone }}</p>
            <p class="order-info">
              Shipping address: {{ item.addressLine1 }}
              {{ item.addressLine2 }}，{{ item.suburb }}, {{ item.state }}
              {{ item.zipCode }}
            </p>
            <p class="order-info">Redeem time: {{ item.createdAt }}</p>
            <p class="order-info" v-if="item.expressNumber">
              Tracking number:{{ item.expressNumber }}
            </p>
            <p class="order-info" v-if="item.comment">
              comment:{{ item.comment }}
            </p>
            <div class="bt-box">
              <LkBt
                v-if="item.status == 2"
                @submit="goPost(item)"
                class="sb-bt"
                :msg="'MORE TRACKING DETAILS'"
              ></LkBt>
              <LkBt
                v-if="item.status == 1"
                @submit="goPageByName(item)"
                class="sb-bt"
                :msg="'UPDATE'"
              ></LkBt>
            </div>
          </div>
        </template>
        <div class="points-empty" v-if="!list.length">
          <div class="img-box">
            <img src="../assets/img/points/icon-empty.png" alt="" />
          </div>
          <p>It’s empty here :-(</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LkBt from "../components/LkBt.vue";
import { getRedeemList } from "@/api/redeem";
import { goPageByName } from "@/util/link";
export default {
  name: "RedeemHistoryView",
  data() {
    return {
      list: [],
      postUrl: "https://auspost.com.au/mypost/track/#/details/",
    };
  },
  components: {
    LkBt,
  },
  mounted() {
    console.log(this.$route.query);
    this.getRedeemList();
  },
  methods: {
    async getRedeemList() {
      const res = await getRedeemList();
      if (res.code === 0) {
        this.list = res.data.list;
      }
    },
    goPageByName(item) {
      goPageByName({
        name: "redeemprizes",
        query: {
          redeemCode: item.redeemCode,
        },
      });
    },
    goPost(item) {
      console.log(item);
      window.location.href = this.postUrl + item.expressNumber;
    },
  },
};
</script>
<style lang="scss" scoped>
.page-redeem-history {
  width: 100%;
  .section-banner {
    width: 100%;
    height: 360px;
    position: relative;
    background: url("../assets/img/redeemhistory/banner.jpg") no-repeat center;
    background-size: 1920px 360px;
  }
  .section-list {
    background: #f4f5f6;
    .content-box {
      width: 1190px;
      margin: 0 auto;
      padding: 40px 0 100px;
    }
    .list-item {
      width: 100%;
      box-sizing: border-box;
      background: #fff;
      padding: 30px 40px 30px;
      margin-bottom: 24px;
      .header {
        height: 34px;
        display: flex;
        justify-content: space-between;
        padding-bottom: 20px;
        border-bottom: 1px dashed #a7a7a8;
        margin-bottom: 20px;
        .order-num {
          font-size: 20px;
          font-family: AlibabaPuHuiTiM;
          color: #000000;
          line-height: 34px;
        }
        .status {
          font-size: 20px;
          font-family: AlibabaPuHuiTiM;
          color: #ff0000;
          line-height: 34px;
        }
      }
      .img-box {
        width: 102px;
        height: 144px;
        background: #e9e9e9;
        border-radius: 5px;
        border: 1px solid #979797;
        overflow: hidden;
        margin-bottom: 20px;
        img {
          object-fit: cover;
        }
      }
      .order-info {
        font-size: 20px;
        font-family: AlibabaPuHuiTiR;
        color: #000000;
        line-height: 34px;
        text-align: left;
        margin-bottom: 10px;
      }
    }
    .bt-box {
      text-align: right;
    }
    .sb-bt::v-deep {
      width: 365px;
      margin-top: 10px 0 10px;
      .ui-lk-bt {
        width: 100%;
        height: 60px;
        line-height: 55px;
        background: #f4cc25;
        font-size: 20px;
        font-family: AlibabaPuHuiTiM;
        color: #000;
        z-index: 10;
        position: relative;
        border-radius: 8px;
        border: 2px solid #000000;
        box-sizing: border-box;
      }
      .ui-lk-bt-i {
        width: 100%;
        height: 60px;
        box-sizing: border-box;
        background: #fff;
        border-radius: 8px;
        border: 2px solid #231815;
        position: absolute;
        z-index: 9;
        top: 9px;
        left: 0;
      }
      &.ui-lk-bt-box:active .ui-lk-bt,
      .ui-lk-bt:active {
        top: 4px;
      }
    }
    .points-empty {
      margin-top: 90px;
      text-align: center;
      .img-box {
        width: 92px;
        height: 76px;
        margin: 0 auto;
      }
      p {
        font-size: 20px;
        font-family: AlibabaPuHuiTiR;
        color: #000000;
        line-height: 34px;
        margin-top: 30px;
      }
    }
  }
}
</style>
