<template>
  <div class="page-about">
    <!-- banner -->
    <div class="section-banner section-box"></div>
    <!-- who -->
    <div class="section-who section-box">
      <div class="content-box">
        <el-collapse class="qa-list" v-model="activeNames">
          <el-collapse-item title="Who Are We?" name="1">
            <p>SURPRISE AND DELIGHT THE WORLD!</p>
            <p>
              We are the number one intelligent vending destination in
              Australia.
            </p>
            <p>
              We are the first smart vending solution in Australia and the first
              to bridge interactive new retailing environments combined with
              AIoT technology and experiential retailing to deliver the best
              surprises and joy to our customers!
            </p>
            <p>
              Lucky Box Australia is part of our AIoT Vending Umbrella including
              Sampling Vending, Trading Card Game vending and much, much more!
            </p>
          </el-collapse-item>
          <el-collapse-item title="What is Lucky Box?" name="2">
            <p>
              Lucky Box Australia produces blind mystery prizes and specifically
              designed to bring excitement, joy and spontaneous liveliness to
              all ages!
            </p>
            <p>
              Our customer focused approach and industry integrated position
              allows us to match the most diverse mystery prize pool in
              Australia and provide players a complete interactive experience
              from the moment you see our vending machines.
              <span class="go" @click="onGo"
                >Our Instagram @luckyboxaustralia</span
              >
              provides the most up to date list of prizes…hopefully we can see
              you shortly on our interactive social media!
            </p>
            <p>
              We collaborate with the largest assets in Australia and have over
              30 Lucky Box Vending Machines nationally…so get hunting and TEST
              YOUR LUCKY DAY!
            </p>
          </el-collapse-item>
          <el-collapse-item
            class="to-store"
            title="Where Can I Find Lucky Box?"
            name="3"
            @click.native="goPageByName('store')"
          >
          </el-collapse-item>
        </el-collapse>
        <!-- <p class="tips">
          We have 34 stores. Please
          <span @click="goPageByName('store')">click here</span> to see details
          location.
        </p> -->
      </div>
    </div>
  </div>
</template>
<script>
import { goPageByName } from "@/util/link";
export default {
  name: "AboutView",
  components: {},
  data() {
    return {
      activeNames: [],
    };
  },
  mounted() {
    console.log(this.$route.query);
  },
  methods: {
    goPageByName(name) {
      goPageByName({
        name,
      });
    },
    onGo() {
      window.location.href =
        "https://instagram.com/luckyboxaustralia?igshid=YmMyMTA2M2Y=";
    },
  },
};
</script>
<style lang="scss" scoped>
.page-about {
  width: 100%;
  .section-banner {
    width: 100%;
    height: 357px;
    position: relative;
    background: url("../assets/img/about/banner.png") no-repeat center;
    background-size: 1920px 357px;
  }
  .section-who {
    width: 100%;
    padding-top: 25px;
    background-color: #f4f5f6;
    .content-box {
      width: 100%;
      min-height: 801px;
      background-image: url("../assets/img/about/bg-who-1.jpg"),
        url("../assets/img/about/bg-who-3.jpg"),
        url("../assets/img/about/bg-who-2.png");
      background-repeat: no-repeat, no-repeat, repeat-y;
      background-size: 1620px 296px, 1620px 228px, 1620px 188px;
      background-position: top center, center bottom, center 296px;
    }
    .qa-list::v-deep {
      width: 1110px;
      padding: 324px 0 100px;
      margin: 0 auto;
      &.el-collapse {
        border: none;
      }
      .el-collapse-item:first-child {
        border-top: 1px dashed #999;
      }
      .el-collapse-item__header {
        height: 100px;
        line-height: 100px;
        font-size: 28px;
        font-family: AlibabaPuHuiTiB;
        color: rgba(14, 14, 14, 0.85);
        border-bottom: 1px dashed #999;
      }
      .el-collapse-item__arrow {
        width: 34px;
        height: 17px;
        background: url("../assets/img/about/icon-arrow-b.png") no-repeat center;
        background-size: 100%;
        &.is-active {
          transform: rotate(180deg);
        }
        &::before {
          content: "";
        }
      }
      .el-collapse-item__content {
        padding: 25px 0;
        font-size: 18px;
        color: #303133;
        line-height: 1.769230769230769;
        text-align: left;
      }
      .to-store {
        .el-collapse-item__wrap {
          display: none;
        }
      }
      .go {
        text-decoration: underline;
        font-weight: bold;
        cursor: pointer;
        color: #ff521f;
      }
    }
    .tips {
      width: 1110px;
      margin: 0 auto;
      font-size: 20px;
      font-family: AlibabaPuHuiTiR;
      color: rgba(14, 14, 14, 0.85);
      line-height: 27px;
      padding: 27px 0 127px;
      text-align: left;
      span {
        color: #0099ff;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}
</style>
