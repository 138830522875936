<template>
  <div class="page-help">
    <div class="section-qa section-box">
      <div class="content-box">
        <h1 class="title">{{ title }}</h1>
        <ul class="qa-list">
          <li v-for="(item, index) in list" :key="index">
            <p class="text-q">{{ item.q }}</p>
            <p
              class="text-a"
              v-for="(itema, indexa) in item.a"
              :key="indexa"
              v-html="itema"
              @click="onBtn"
            ></p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import helpdata from "../util/helpdata";
import { goPageByName } from "@/util/link";

export default {
  name: "HelpView",
  data() {
    return {
      title: "",
      list: [],
      type: "faq",
    };
  },
  components: {},
  mounted() {
    console.log(this.$route.query);
    console.log("helpdata", helpdata);
    this.type = this.$route.query.type || this.type;
    this.title = helpdata[this.type].title;
    this.list = helpdata[this.type].list;
  },
  methods: {
    onBtn(e) {
      if (e.target.id == "store") {
        goPageByName({
          name: "store",
        });
      }
      if (e.target.id == "logon") {
        goPageByName({
          name: "logon",
        });
      }
    },
  },
};
</script>
<style>
.page-help #store,
.page-help #logon {
  text-decoration: underline;
  font-weight: bold;
  cursor: pointer;
  color: #ff521f;
}
</style>
<style lang="scss">
.page-help {
  width: 100%;
  background: #f4f5f6;
  .section-qa {
    width: 100%;
    .content-box {
      width: 1190px;
      padding: 100px 0 120px;
    }
    .title {
      font-size: 40px;
      font-family: AlibabaPuHuiTiH;
      color: #000000;
      line-height: 55px;
    }
    .qa-list {
      width: 740px;
      text-align: left;
      margin: 0 auto;
      li {
        margin-top: 40px;
        .text-q {
          font-size: 20px;
          font-family: AlibabaPuHuiTiH;
          color: #000000;
          line-height: 27px;
        }
        .text-a {
          font-size: 16px;
          font-family: AlibabaPuHuiTiR;
          color: #000000;
          line-height: 24px;
          margin-top: 20px;
        }
      }
    }
  }
}
</style>
