import { request } from "@/common/request";
import url from "@/common/config/url";

// 提交心愿/故事
export function getPointsLog(params) {
  return request({
    url: url.getPointsLog,
    method: "get",
    params,
  });
}
