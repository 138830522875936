<template>
  <div class="page-address">
    <!-- banner -->
    <div class="section-banner section-box"></div>
    <!-- form -->
    <div class="section-form section-box">
      <div class="content-box">
        <el-form
          class="form-box"
          :label-position="labelPosition"
          label-width="80px"
          :model="form"
          :rules="RULES"
          ref="form"
          @submit.native.prevent
        >
          <el-form-item
            label="First Name*"
            class="input-part ui-m-r-40"
            prop="firstName"
          >
            <el-input v-model="form.firstName"></el-input>
          </el-form-item>
          <el-form-item label="Last Name" class="input-part" prop="lastName">
            <el-input v-model="form.lastName"></el-input>
          </el-form-item>
          <el-form-item
            label="Phone Number"
            prop="phone"
            class="ui-m-t-50 phone-box"
          >
            <el-input v-model="form.phone"></el-input>
            <i>+61</i>
          </el-form-item>
          <el-form-item label="Email" prop="email">
            <el-input v-model="form.email"></el-input>
          </el-form-item>
          <el-form-item label="Address Line 1" prop="addressLine1">
            <el-input v-model="form.addressLine1"></el-input>
          </el-form-item>
          <el-form-item label="Address Line 2 (optional)" prop="addressLine2">
            <el-input v-model="form.addressLine2"></el-input>
          </el-form-item>
          <el-form-item
            @click.native="onSelect"
            label="Select City/Suburb"
            :class="{ error: isSelectError }"
          >
            <el-cascader
              v-model="value"
              :options="options"
              :props="{ ...fieldNames }"
              @change="handleChange"
              filterable
            ></el-cascader>
            <p class="tips">Please enter First Name</p>
          </el-form-item>
          <el-form-item label="Post / Zip Code" prop="zipCode">
            <el-input v-model="form.zipCode"></el-input>
          </el-form-item>
          <LkBt @submit="onSubmit" class="sb-bt" :msg="'CONFIRM'"></LkBt>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import LkBt from "../components/LkBt.vue";
import { setAddress, myAddress } from "@/api/address";
import { submitRedeem } from "@/api/redeem";
import { goPageByName } from "@/util/link";
import { mapState } from "vuex";
// import { isAddressTimeOver } from "@/util/index";
import RULES from "@/util/rules";
export default {
  name: "AddressView",
  data() {
    return {
      labelPosition: "top",
      cascaderValue: "",
      cascadershow: false,
      options: [],
      fieldNames: {
        label: "name",
        value: "name",
        children: "children",
      },
      form: {
        firstName: "",
        lastName: "",
        phone: "",
        addressLine1: "",
        addressLine2: "",
        email: "",
        // 州
        state: "",
        // 郊区
        suburb: "",
        zipCode: "",
      },
      isStateError: false,
      isNeedShowCascader: false,
      screenshot: "",
      codeId: "",
      type: "",
      value: [],
      isSelectError: false,
      loading: "",
      RULES,
    };
  },
  components: {
    LkBt,
  },
  computed: {
    ...mapState(["isLogin"]),
  },
  watch: {
    options(newval) {
      if (newval.length > 0 && this.loading) {
        this.loading.close();
      }
    },
  },
  mounted() {
    if (!this.isLogin) {
      goPageByName({ name: "logon" });
      return;
    }
    console.log(this.$route.query);
    this.screenshot = this.$route.query.screenshot;
    this.codeId = this.$route.query.codeId;
    this.redeemCode = this.$route.query.redeemCode;
    this.type = this.$route.query.type;
    this.myAddress();
    if (window.AddressData) {
      this.options = window.AddressData;
    } else {
      this.getAllAddress();
    }
  },
  methods: {
    onSelect() {
      console.log(123123123);
      if (this.options.length) {
        return;
      }
      this.loading = this.$loading({
        lock: true,
        text: "Loading",
      });
    },
    handleChange(e) {
      this.isSelectError = false;
      this.form.state = e[0];
      this.form.suburb = e[1];
      console.log(e);
    },
    onSubmit() {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          if (!this.form.state || !this.form.suburb) {
            this.isSelectError = true;
            return;
          }
          const res = await setAddress(this.form);
          if (res.code === 0) {
            if (this.type === "redeemprizes") {
              this.submitRedeem();
            } else {
              this.$message({
                message: "success",
                type: "success",
              });
            }
          } else {
            this.$message(res.msg);
          }
        } else {
          return false;
        }
      });
    },
    onFinish(value) {
      this.cascadershow = false;
      this.isStateError = false;
      this.form.state = value.selectedOptions[0].name;
      this.form.suburb = value.selectedOptions[1].name;
    },
    onChange() {},
    async submitRedeem() {
      const data = this.form;
      data.screenshot = this.screenshot;
      data.codeId = this.codeId;
      data.redeemCode = this.redeemCode;
      const res = await submitRedeem(data);
      if (res.code === 0) {
        goPageByName({ name: "remeemsuccess" });
      } else {
        this.$message(res.msg);
      }
    },
    getAllAddress() {
      setTimeout(() => {
        if (window.AddressData) {
          this.options = window.AddressData;
        } else {
          this.getAllAddress();
        }
      }, 1000);
    },
    onShowCascader() {
      if (!this.options.length) {
        this.isNeedShowCascader = true;
        this.$message.loading({
          message: "Loading...",
          forbidClick: true,
          duration: 0,
        });
      } else {
        this.cascadershow = true;
      }
    },
    async myAddress() {
      const res = await myAddress();
      if (res.code === 0) {
        console.log(res);
        this.form = res.data;
        if (this.form.state) {
          this.isSelectError = false;
          this.value = [this.form.state, this.form.suburb];
        }
      } else {
        this.$message(res.msg);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.page-address {
  width: 100%;
  .section-banner {
    width: 100%;
    height: 360px;
    position: relative;
    background: url("../assets/img/address/banner.jpg") no-repeat center;
    background-size: 1920px 360px;
  }
  .section-form {
    background: #f4f5f6;
    .content-box {
      width: 740px;
      margin: 0 auto;
      padding: 45px 0 120px;
    }
    .form-box::v-deep {
      text-align: left;
      &.el-form--label-top .el-form-item__label {
        font-size: 20px;
        font-family: AlibabaPuHuiTiR;
        color: #000000;
        line-height: 27px;
      }
      .input-part {
        width: 350px;
        display: inline-block;
      }
      .el-input__inner {
        height: 60px;
        border-radius: 8px;
        border: 1px solid #979797;
        background: #f4f5f6;
        font-size: 20px;
      }
      .el-form-item {
        margin-top: 40px;
        margin-bottom: 0;
      }
      .el-col-11 {
        width: 100%;
      }
      .is-error {
        .el-input__inner {
          border: 1px solid #ff0000;
        }
      }
      .el-form-item__error {
        font-size: 18px;
        font-family: AlibabaPuHuiTiR;
        color: #ff0000;
        line-height: 26px;
        // margin-top: 10px;
        padding: 0;
      }
      .el-cascader {
        width: 100%;
      }
      .ui-m-t-50 {
        margin-top: 50px;
      }
      .error {
        .el-input__inner {
          border: 1px solid #ff0000;
        }
        .tips {
          font-size: 18px;
          font-family: AlibabaPuHuiTiR;
          color: #ff0000;
          line-height: 26px;
          padding: 0;
          position: absolute;
          top: 100%;
          left: 0;
          display: block;
        }
      }
      .tips {
        display: none;
      }
      .phone-box {
        position: relative;
        .el-input__inner {
          padding-left: 60px;
        }
        i {
          line-height: 60px;
          font-size: 20px;
          color: #000;
          position: absolute;
          bottom: 0;
          left: 20px;
        }
      }
    }
    .city-box {
      margin-top: 40px;
      position: relative;
      &.error {
        .input {
          border: 1px solid #ff0000;
        }
        .tips {
          display: block;
        }
      }
      .label {
        font-size: 20px;
        font-family: AlibabaPuHuiTiR;
        color: #000000;
        line-height: 27px;
        padding: 0 0 10px;
      }
      .input {
        height: 60px;
        line-height: 60px;
        border-radius: 8px;
        border: 1px solid #979797;
        background: #f4f5f6;
        font-size: 20px;
        padding: 0 15px;
        box-sizing: border-box;
        color: #606266;
      }
      .tips {
        font-size: 18px;
        font-family: AlibabaPuHuiTiR;
        color: #ff0000;
        line-height: 26px;
        padding: 0;
        position: absolute;
        top: 100%;
        left: 0;
        display: none;
      }
    }
    .sb-bt::v-deep {
      width: 740px;
      margin-top: 60px;
      .ui-lk-bt {
        width: 100%;
        height: 60px;
        line-height: 55px;
        background: #f4cc25;
        font-size: 20px;
        font-family: AlibabaPuHuiTiM;
        color: #000;
        z-index: 10;
        position: relative;
        border-radius: 8px;
        border: 2px solid #000000;
        box-sizing: border-box;
      }
      .ui-lk-bt-i {
        width: 100%;
        height: 60px;
        box-sizing: border-box;
        background: #fff;
        border-radius: 8px;
        border: 2px solid #231815;
        position: absolute;
        z-index: 9;
        top: 9px;
        left: 0;
      }
      &.ui-lk-bt-box:active .ui-lk-bt,
      .ui-lk-bt:active {
        top: 4px;
      }
    }
    .text-policy {
      height: 26px;
      font-size: 16px;
      font-family: AlibabaPuHuiTiR;
      color: #000000;
      line-height: 26px;
      display: flex;
      align-items: center;
      margin-top: 20px;
      i {
        width: 18px;
        height: 18px;
        background: url("../assets/img/create/icon-n.png") no-repeat center;
        background-size: 100%;
        margin-right: 5px;
        cursor: pointer;
        &.active {
          background: url("../assets/img/create/icon-y.png") no-repeat center;
          background-size: 100%;
        }
      }
      span {
        color: #000;
        font-weight: bold;
        margin: 0 5px;
      }
    }
  }
}
</style>
