<template>
  <el-dialog
    class="lk-dialog"
    title=""
    :before-close="close"
    :visible.sync="dialogVisible"
  >
    <h1 class="title" v-html="title"></h1>
    <div class="textarea-box">
      <el-input type="textarea" v-model="desc"></el-input>
    </div>
    <LkBt @submit="submit" class="bt" :msg="bt"></LkBt>
  </el-dialog>
</template>

<script>
import LkBt from "./LkBt.vue";
export default {
  name: "LkAlert",
  // props: {
  //   title: {
  //     type: String,
  //     default: "Thanks for your share!",
  //   },
  //   text: {
  //     type: String,
  //     default: "Your information has been submitted successfully.",
  //   },
  // },
  components: {
    LkBt,
  },
  data() {
    return {
      title: "Thanks for your share!",
      bt: "share",
      dialogVisible: false,
      desc: "",
      type: "",
    };
  },
  methods: {
    show({ title, bt, type, desc }) {
      this.title = title || this.title;
      this.bt = bt || this.bt;
      this.type = type;
      this.desc = desc;
      this.dialogVisible = true;
    },
    close() {
      this.dialogVisible = false;
      this.$emit("close", { type: this.type, desc: this.desc });
    },
    submit() {
      this.$emit("submit", { type: this.type, desc: this.desc });
      // this.close();
    },
  },
};
</script>
<style lang="scss" scoped>
.lk-dialog::v-deep {
  box-sizing: border-box;
  text-align: center;
  .el-dialog {
    width: 640px;
  }
  .el-dialog__body {
    padding: 40px 60px;
  }
  .el-dialog__header {
    padding: 0;
  }
  .title {
    font-size: 26px;
    font-family: AlibabaPuHuiTiH;
    color: #000000;
    line-height: 35px;
    word-break: break-word;
    word-spacing: -6px;
  }
  .textarea-box {
    margin-top: 30px;
    textarea {
      width: 520px;
      height: 337px;
      border-radius: 8px;
      border: 1px solid #000000;
    }
  }
  .el-textarea__inner {
    padding: 15px;
    font-size: 20px;
    font-family: AlibabaPuHuiTiM;
    color: #000000;
  }
  .el-dialog__headerbtn .el-dialog__close {
    width: 16px;
    height: 16px;
    background: url("../assets/img/membership/icon-x.png") no-repeat center;
    background-size: 100%;
  }
  .el-icon-close:before {
    display: none;
  }
  .el-dialog__headerbtn {
    top: 30px;
    right: 30px;
  }
}
.bt::v-deep {
  width: 200px;
  margin-top: 40px;
  .ui-lk-bt {
    width: 100%;
    height: 60px;
    line-height: 55px;
    background: #f4cc25;
    font-size: 20px;
    font-family: AlibabaPuHuiTiM;
    color: #000;
    z-index: 10;
    position: relative;
    border-radius: 8px;
    border: 2px solid #000000;
    box-sizing: border-box;
  }
  .ui-lk-bt-i {
    width: 100%;
    height: 60px;
    box-sizing: border-box;
    background: #fff;
    border-radius: 8px;
    border: 2px solid #231815;
    position: absolute;
    z-index: 9;
    top: 9px;
    left: 0;
  }
  &.ui-lk-bt-box:active .ui-lk-bt,
  .ui-lk-bt:active {
    top: 4px;
  }
}
</style>
