import { request } from "@/common/request";
import url from "@/common/config/url";

// 注册
export function register(data) {
  return request({
    url: url.register,
    method: "post",
    data,
  });
}

// 获取登录用户信息
export function getInfo(params) {
  return request({
    url: url.getInfo,
    method: "get",
    params,
  });
}

// 邮箱密码登录
export function loginByEmailPwd(data) {
  return request({
    url: url.loginByEmailPwd,
    method: "post",
    data,
  });
}

// 修改个人信息接口
export function saveUserInfo(data) {
  return request({
    url: url.saveUserInfo,
    method: "post",
    data,
  });
}

// 重置密码
export function resetPwd(data) {
  return request({
    url: url.resetPwd,
    method: "post",
    data,
  });
}

// 发送重置密码邮件
export function forgetPwd(data) {
  return request({
    url: url.forgetPwd,
    method: "post",
    data,
  });
}
